import SideBarComponent from "./SideBarComponent";
import underConstruction from "./images/underconstruction.jpg";

export default function Properties() {

    return (
        <div className="container">
            <div className="row">
                
                <div className="col-3 col-md-2">
                    <SideBarComponent />
                </div>

                <div className="col-15 col-md-10">
                    <div className="row">
                        <h1>My Properties</h1>
                    </div>
                    <div className="row mb-5">
                        <h4>Manage your properties:</h4>
                        <h6 className="lead">
                            Your List of properties at once central place for an overview.    
                        </h6>
                        <img src={underConstruction} aria-label="Under Construction" alt="Under Construction" />
                    </div>
                </div>

            </div>
        </div>
    )
}