import LoginComponent from "./LoginComponent";
import WelcomeComponent from "./WelcomeComponent";
import './TMSApp.css';
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import ErrorComponent from "./ErrorComponent";
import AboutUsComponent from "./AboutUsComponent";
import ListTenantsComponent from "./ListTenantsComponent";
import ContactUsComponent from "./ContactUsComponent";
import HeaderComponent from "./HeaderComponent";
import FooterComponent from "./FooterComponent";
import LogoutComponent from "./LogoutComponent";
import AuthProvider, { useAuth } from "./security/AuthContext";
import TenantComponent from "./TenantComponent";
import TestimonialsComponent from "./TestimonialsComponent";
import GalleryComponent from "./GalleryComponent";
import RegistrationComponent from "./RegistrationComponent";
import ShoppingCartComponent from "./ShoppingCartComponent";
import GiftsComponent from "./GiftsComponent";
import DashboardComponent from "./DashboardComponent";
import FAQsComponent from "./FAQsComponent";
import TicketComponent from "./TicketComponent";
import ListTicketsComponent from "./ListTicketsComponent";
import SubmitTestimonialComponent from "./SubmitTestimonialComponent";
import ForgotPasswordComponent from "./ForgotPasswordComponent";
import Properties from "./PropertiesComponent";
import ReportsComponent from "./ReportsComponent";
import AccountsComponent from "./AccountsComponent";
import SettingsComponent from "./SettingsComponent";
import MaintenanceComponent from "./MaintenanceComponent";
import RevenueComponent from "./RevenueComponent";
import SalesComponent from "./SalesComponent";
import AboutUsManagementComponent from "./AboutUsManagementComponent";
import GalleriesComponent from "./GalleriesComponent";
import ContactsComponent from "./ContactsComponent";
import ScrollToTop from "./utils/ScrollToTop";

function AuthenticatedRoute({children}) {

  const authContext = useAuth();
  if(authContext.isAuthenticated) {
    return (
      children
    )
  }

  return <Navigate to="/" />
}

export default function TMSApp() {
    return (
      <div className="TMSApp">
        
        <AuthProvider>

          <BrowserRouter>

            <ScrollToTop />

            <HeaderComponent />

              <Routes>
                <Route path="/" element={ <WelcomeComponent /> } />
                <Route path="/sign-in" element={ <LoginComponent /> } />

                <Route path="/welcome" element={ 
                  <WelcomeComponent />
                } />
                
                <Route path="/about-us" element={ 
                  <AboutUsComponent />
                } />

                <Route path="/about-us-management/:username" element={
                  <AuthenticatedRoute>
                    <AboutUsManagementComponent />
                  </AuthenticatedRoute>
                } />

                <Route path="/gallery" element={
                  <GalleryComponent />
                } />

                <Route path="/galleries/:username" element={
                  <AuthenticatedRoute>
                    <GalleriesComponent />
                  </AuthenticatedRoute>
                } />

                <Route path="/testimonials" element={
                  <TestimonialsComponent />
                } />

                <Route path="/submit-testimonial" element={
                  <AuthenticatedRoute>
                    <SubmitTestimonialComponent />
                  </AuthenticatedRoute>
                } />
                
                <Route path="/contact-us" element={ 
                  <ContactUsComponent />
                } />

                <Route path="/contacts/:username" element={
                  <AuthenticatedRoute>
                    <ContactsComponent />
                  </AuthenticatedRoute>
                } />

                <Route path="/dashboard/:username" element={
                  <AuthenticatedRoute>
                    <DashboardComponent />
                  </AuthenticatedRoute>
                } />

                <Route path="/properties/:username" element={
                  <AuthenticatedRoute>
                    <Properties />
                  </AuthenticatedRoute>
                } />

                <Route path="/sales/:username" element={
                  <AuthenticatedRoute>
                    <SalesComponent />
                  </AuthenticatedRoute>
                } />

                <Route path="/revenue/:username" element={
                  <AuthenticatedRoute>
                    <RevenueComponent />
                  </AuthenticatedRoute>
                } />

                <Route path="/faqs/:username" element={
                  <AuthenticatedRoute>
                    <FAQsComponent />
                  </AuthenticatedRoute>
                } />

                <Route path="/ticket/:username/:id" element={
                  <AuthenticatedRoute>
                    <TicketComponent />
                  </AuthenticatedRoute>
                } />

                <Route path="/tickets/:username" element={
                  <AuthenticatedRoute>
                    <ListTicketsComponent />
                  </AuthenticatedRoute>
                } />
                
                <Route path="/tenants/:username" element={ 
                  <AuthenticatedRoute>
                    <ListTenantsComponent />
                  </AuthenticatedRoute> 
                } />

                <Route path="/reports/:username" element={
                  <AuthenticatedRoute>
                    <ReportsComponent />
                  </AuthenticatedRoute>
                } />

                <Route path="/tenant/:id" element={
                  <AuthenticatedRoute>
                    <TenantComponent />
                  </AuthenticatedRoute>
                } />
                
                <Route path="/logout" element={
                  <LogoutComponent />
                } />

                <Route path="/maintenance/:username" element={
                  <AuthenticatedRoute>
                    <MaintenanceComponent />
                  </AuthenticatedRoute>
                } />

                <Route path="/accounts/:username" element={
                  <AuthenticatedRoute>
                    <AccountsComponent />
                  </AuthenticatedRoute>
                } />

                <Route path="/settings/:username" element={
                  <AuthenticatedRoute>
                    <SettingsComponent />
                  </AuthenticatedRoute>
                } />

                <Route path="/sign-up" element={
                  <RegistrationComponent />
                } />

                <Route path="forgot-password" element={
                  <ForgotPasswordComponent />
                } />

                <Route path="/shopping-cart" element={
                  <ShoppingCartComponent />
                } />

                <Route path="/gifts" element={
                  <GiftsComponent />
                } />

                <Route path="*" element={ <ErrorComponent /> } />
              </Routes>
            
            <FooterComponent />
            
          </BrowserRouter>
        </AuthProvider>
      </div>
    );
  }