import { useLocation, useNavigate, useParams } from "react-router-dom";
import SideBarComponent from "./SideBarComponent";
import { useEffect, useState } from "react";
import { useAuth } from "./security/AuthContext";
import retrieveAllTicketsApi, { deleteTicketApi } from "./api/SupportTicketApiService";
import { formateDateTimeByRemovingT, replaceAllUnderScores } from "./utils/commonUtility";

export default function ListTicketsComponent() {
    
    const {username} = useParams();

    const [message, setMessage] = useState(null);
    
    const location = useLocation();

    const navigate = useNavigate();

    const [tickets, setTickets] = useState();

    const authContext = useAuth();

    useEffect ( () => refreshTicketList(authContext), [authContext]);

    function refreshTicketList(authContext) {
        retrieveAllTicketsApi(authContext.token)
        .then(
            (response) => {
                setTickets(response.data);
            }
        )
        .catch(error => console.log(error));
    }

    function deleteTicket(ticket) {
        deleteTicketApi(ticket.id)
        .then(
            () => {
                setMessage(`Deletion of Ticket Number ${ticket.ticketNumber} was successful!`);
                refreshTicketList(authContext);
            }
        )
        .catch(error => console.log(error));
    }

    function updateTicket(ticket) {
        navigate(`/ticket/${username}/${ticket.id}`);
    }

    function submitNewTicket() {
        navigate(`/ticket/${username}/-1`);
    }

    return (
        <div className="container">
            <div className="row">

                <div className="col-3 col-md-2">
                    <SideBarComponent />
                </div>

                <div className="col-15 col-md-10">
                    <div className="row">
                        <h1>List All Tickets</h1>
                    </div>

                    <div className="row">
                        <h4>Manage your tickets:</h4>
                        <h6 className="lead">
                            Manage all your ticket(s) here by getting an overview, raise, update, search, and remove tickets.
                        </h6>
                    </div>
                    
                    <div className="row">
                        {message && <div className="alert alert-warning">{message}</div>}
                    </div>

                    <div className="row">
                        {location.state && <div className="alert alert-success">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-check-circle-fill" viewBox="0 0 16 16">
                                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
                            </svg>&nbsp;
                            <strong>Ticket Number: #{location.state.ticketNumber}</strong> was successfully {location.state.operation}.</div>
                        }
                    </div>

                    <div className="row">
                        <div className="table-responsive">
                            <table className="table table-striped table-hover table-responsive caption-top">
                                
                                <caption>Support Ticket Info:</caption>
                                
                                <thead className="table-dark">
                                    <tr>
                                        <th>Ticket No.:</th>
                                        <th>Title</th>
                                        <th>Description</th>
                                        <th>Raised By</th>
                                        <th>Date Raised</th>
                                        <th>Priority</th>
                                        <th>Status</th>
                                        <th colSpan={2}>Actions</th>
                                    </tr>
                                </thead>

                                <tbody>
                                    {
                                        (tickets &&
                                            (tickets.length == 0)
                                        ) ?
                                        (
                                            <tr>
                                                <td colSpan={8} className="text-danger">No Results</td>
                                            </tr>
                                        )
                                        :
                                        tickets && tickets.map((ticket) =>
                                            (
                                                <tr key={ticket.id}>
                                                    <td>#{ticket.ticketNumber}</td>
                                                    <td>
                                                        <span className="text-truncate text-ellipsis">
                                                            <abbr title={ticket.title}>
                                                                {ticket.title}
                                                            </abbr>
                                                        </span>
                                                    </td>
                                                    <td>
                                                        <span className="text-truncate text-ellipsis">
                                                            <abbr title={ticket.description}>
                                                                {ticket.description}
                                                            </abbr>
                                                        </span>
                                                    </td>
                                                    <td>{ticket.raisedBy}</td>
                                                    <td>{formateDateTimeByRemovingT(ticket.dateRaised)}</td>
                                                    <td>{ticket.priority}</td>
                                                    <td>{replaceAllUnderScores(ticket.status)}</td>
                                                    <td>
                                                        <svg aria-label="Update ticket" onClick={ () => updateTicket(ticket) } xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-pencil-fill text-warning pointer-link" viewBox="0 0 16 16">
                                                            <path d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.5.5 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11z"/>
                                                        </svg>
                                                    </td>
                                                    <td>
                                                        <svg aria-label="Delete ticket" onClick={ () => deleteTicket(ticket) } xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-trash3-fill text-danger pointer-link" viewBox="0 0 16 16">
                                                            <path d="M11 1.5v1h3.5a.5.5 0 0 1 0 1h-.538l-.853 10.66A2 2 0 0 1 11.115 16h-6.23a2 2 0 0 1-1.994-1.84L2.038 3.5H1.5a.5.5 0 0 1 0-1H5v-1A1.5 1.5 0 0 1 6.5 0h3A1.5 1.5 0 0 1 11 1.5m-5 0v1h4v-1a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5M4.5 5.029l.5 8.5a.5.5 0 1 0 .998-.06l-.5-8.5a.5.5 0 1 0-.998.06m6.53-.528a.5.5 0 0 0-.528.47l-.5 8.5a.5.5 0 0 0 .998.058l.5-8.5a.5.5 0 0 0-.47-.528M8 4.5a.5.5 0 0 0-.5.5v8.5a.5.5 0 0 0 1 0V5a.5.5 0 0 0-.5-.5"/>
                                                        </svg>
                                                    </td>
                                                </tr>
                                            )
                                        )
                                    }
                                    
                                </tbody>

                                <tfoot className="table-dark">
                                    <tr>
                                        <td colSpan={9}>
                                            <h6><small>Table Data &copy;</small></h6>
                                        </td>
                                    </tr>
                                </tfoot>
                            
                            </table>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col">
                            <button aria-label="Submit New Ticket" className="btn btn-success m-3" onClick={submitNewTicket}>
                                Submit New Ticket
                            </button>
                        </div>
                    </div>
                </div>
                
            </div>
        </div>
    )
}