import { apiClient } from "./ApiClient";

export default function retrieveAllTenantsApi(token) {
    return apiClient.get('/tenants/find/all');
}

export function retrieveTenantByIdApi(id) {
    return apiClient.get(`/tenants/find/by/${id}`);
}

export function deleteTenantApi(id) {
    return apiClient.delete(`/tenants/delete/${id}`);
}

export function updateTenantApi(id, tenant) {
    return apiClient.put(`/tenants/update/${id}`, tenant);
}

export function createTenantApi(tenant) {
    return apiClient.post(`/tenants/create`, tenant);
}

export function createContactUsApi(contactUs) {
    return apiClient.post(`/contact-us/create`, contactUs);
}