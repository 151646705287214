const images = [
    {
        "style": "d-block carouselSize w-100",
        "image": require("./images/hero-image.jpg"),
        "alternateText": "Live Remarkably",
        "title": "Live Remarkably",
        "subTitle": "Let us help you find a rental property to fall in love with."
    },
    {
        "style": "d-block carouselSize w-100",
        "image": require("./images/RentalKeysOnWoodenTableDarker.jpg"),
        "alternateText": "Summer has arrived :)",
        "title": "Summer has arrived",
        "subTitle": "Don't miss your opportunity to secure your space with us."
    },
    {
        "style": "d-block carouselSize w-100",
        "image": require("./images/HD_OutsideView.jpg"),
        "alternateText": "Come stay with us",
        "title": "Come stay with us",
        "subTitle": "We bring nothing but the best in all your rental needs."
    },
    {
        "style": "d-block carouselSize w-100",
        "image": require("./images/rental-housing.jpg"),
        "alternateText": "Easy and afforable living",
        "title": "Everything is pre-installed",
        "subTitle": "You typically need a few things to get start to enjoy our stylish apartments at affordable prices."
    }
]

export default images;