const images = [    
    {
        "style": "carouselGallerySize gallery-w-100",
        "image": require("./images/IMG_20220826_173609.jpg"),
        "alternateText": "Summer has arrived :)",
        "title": "Summer has arrived",
        "subTitle": "Don't miss your opportunity to secure your space with us."
    },
    {
        "style": "carouselGallerySize gallery-w-100",
        "image": require("./images/IMG-20240205-WA0001.jpg"),
        "alternateText": "Summer has arrived :)",
        "title": "Summer has arrived",
        "subTitle": "Don't miss your opportunity to secure your space with us."
    },
    {
        "style": "carouselGallerySize gallery-w-100",
        "image": require("./images/IMG-20240205-WA0003.jpg"),
        "alternateText": "Summer has arrived :)",
        "title": "Summer has arrived",
        "subTitle": "Don't miss your opportunity to secure your space with us."
    },
    {
        "style": "carouselGallerySize gallery-w-100",
        "image": require("./images/IMG-20240205-WA0002.jpg"),
        "alternateText": "Summer has arrived :)",
        "title": "Summer has arrived",
        "subTitle": "Don't miss your opportunity to secure your space with us."
    },
    {
        "style": "carouselGallerySize gallery-w-100",
        "image": require("./images/IMG-20240205-WA0008.jpg"),
        "alternateText": "Summer has arrived :)",
        "title": "Summer has arrived",
        "subTitle": "Don't miss your opportunity to secure your space with us."
    },
    {
        "style": "carouselGallerySize gallery-w-100",
        "image": require("./images/IMG-20240205-WA0005.jpg"),
        "alternateText": "Summer has arrived :)",
        "title": "Summer has arrived",
        "subTitle": "Don't miss your opportunity to secure your space with us."
    },
    {
        "style": "carouselGallerySize gallery-w-100",
        "image": require("./images/IMG-20240205-WA0006.jpg"),
        "alternateText": "Summer has arrived :)",
        "title": "Summer has arrived",
        "subTitle": "Don't miss your opportunity to secure your space with us."
    },
    {
        "style": "carouselGallerySize gallery-w-100",
        "image": require("./images/IMG-20240205-WA0020.jpg"),
        "alternateText": "Summer has arrived :)",
        "title": "Summer has arrived",
        "subTitle": "Don't miss your opportunity to secure your space with us."
    },
    {
        "style": "carouselGallerySize gallery-w-100",
        "image": require("./images/IMG-20240205-WA0022.jpg"),
        "alternateText": "Summer has arrived :)",
        "title": "Summer has arrived",
        "subTitle": "Don't miss your opportunity to secure your space with us."
    },
    {
        "style": "carouselGallerySize gallery-w-100",
        "image": require("./images/IMG-20240205-WA0024.jpg"),
        "alternateText": "Summer has arrived :)",
        "title": "Summer has arrived",
        "subTitle": "Don't miss your opportunity to secure your space with us."
    },
    {
        "style": "carouselGallerySize gallery-w-100",
        "image": require("./images/IMG-20240205-WA0026.jpg"),
        "alternateText": "Summer has arrived :)",
        "title": "Summer has arrived",
        "subTitle": "Don't miss your opportunity to secure your space with us."
    },
    {
        "style": "carouselGallerySize gallery-w-100",
        "image": require("./images/IMG_20220802_162202.jpg"),
        "alternateText": "Summer has arrived :)",
        "title": "Summer has arrived",
        "subTitle": "Don't miss your opportunity to secure your space with us."
    },    
    {
        "style": "carouselGallerySize gallery-w-100",
        "image": require("./images/IMG_20230526_120508.jpg"),
        "alternateText": "Summer has arrived :)",
        "title": "Summer has arrived",
        "subTitle": "Don't miss your opportunity to secure your space with us."
    },
    {
        "style": "carouselGallerySize gallery-w-100",
        "image": require("./images/IMG_20230530_121542.jpg"),
        "alternateText": "Summer has arrived :)",
        "title": "Summer has arrived",
        "subTitle": "Don't miss your opportunity to secure your space with us."
    },
    {
        "style": "carouselGallerySize gallery-w-100",
        "image": require("./images/IMG_20230610_132112.jpg"),
        "alternateText": "Summer has arrived :)",
        "title": "Summer has arrived",
        "subTitle": "Don't miss your opportunity to secure your space with us."
    },
    {
        "style": "carouselGallerySize gallery-w-100",
        "image": require("./images/IMG_20230610_132201.jpg"),
        "alternateText": "Summer has arrived :)",
        "title": "Summer has arrived",
        "subTitle": "Don't miss your opportunity to secure your space with us."
    },   
    {
        "style": "carouselGallerySize gallery-w-100",
        "image": require("./images/IMG_20230610_132256.jpg"),
        "alternateText": "Summer has arrived :)",
        "title": "Summer has arrived",
        "subTitle": "Don't miss your opportunity to secure your space with us."
    },
    {
        "style": "carouselGallerySize gallery-w-100",
        "image": require("./images/IMG_20230622_174811.jpg"),
        "alternateText": "Summer has arrived :)",
        "title": "Summer has arrived",
        "subTitle": "Don't miss your opportunity to secure your space with us."
    },
    
]

export default images;