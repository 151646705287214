import Carousel from 'react-bootstrap/Carousel';

export default function CarouselGalleryComponent({slides}) {
    
    return (

        <Carousel data-bs-theme="dark">
            {slides.map((slide) => (
                <Carousel.Item key={slide.image}>
                    <img
                        className={slide.style}
                        src={slide.image}
                        alt={slide.alternateText}
                    />
                </Carousel.Item>                 
            ))}
        </Carousel>
            
    )
}