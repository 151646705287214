import { Link } from "react-router-dom";
import CarouselGalleryComponent from "./CarouselGalleryComponent";
import slides from './mockGallery';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useState } from "react";
import Bedrooms from "./images/icon_bed_blue.svg";
import WifiService from "./images/icon_fibre_blue.svg";
import Bathroom from "./images/icon_bathroom_blue.svg";
import SecureParking from "./images/icon_secure_parking_blue.svg";
import Accordion from 'react-bootstrap/Accordion';

function MyVerticallyCenteredModal(props) {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Available Apartment(s)
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Accordion defaultActiveKey={['0']} alwaysOpen>
                <Accordion.Item eventKey="0">
                    <Accordion.Header>
                        <h4>
                            <span className="color-b">Stunning</span>
                            <span className="color-d">Bachelor</span> 
                            <span className="color-c">Apartment</span>
                            &nbsp;
                            <sup>
                                <ins>
                                    <small>
                                    x 2 Under Construction
                                    </small>
                                </ins>
                            </sup>
                        </h4>
                    </Accordion.Header>
                    <Accordion.Body>
                        <p>
                            <strong>Description</strong>
                        </p>
                        <p>
                            <strong>
                                <em>
                                    <img src={WifiService} alt="FREE Unlimited Wi-Fi Service" /> FREE Unlimited Wi-Fi Service</em>
                            </strong>
                        <br />
                        <br />
                        <img src={Bedrooms} alt="Bedroom" /> Bachelor bedroom apartment with built-in closet, stunning kitchen unit with built-in stove and oven. Prepaid 
                        electricity. Water included. 
                        <br />
                        <img src={Bathroom} alt="Bathroom" /> Bathroom has shower glass door, toilet, basin, bathroom mirror cabinet, and hot shower. Has USB plug, DStv 
                        plug and play. 
                        <br />
                        <img src={SecureParking} alt="Secure Parking" /> Free parking space, with braai area ideal for friends and family. The apartment is secure, close to 
                        schools, shopping complex, and malls
                        </p>
                        <p>
                            <strong>Documents Required</strong>
                        </p>
                        <ul>
                            <li>ID Documents/Passport</li>
                            <li>Latest payslip</li>
                            <li>3-6 months banking statement</li>
                        </ul>
                    </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="1">
                    <Accordion.Header>
                        <h4>
                            <span className="color-b">1</span> 
                            <span className="color-d">Bedroom</span> 
                            <span className="color-c">Apartment</span>
                            &nbsp;
                            <sup>
                                <ins>
                                    <small>
                                       x 1 Under Construction
                                    </small>
                                </ins>
                            </sup>
                        </h4>
                    </Accordion.Header>
                    <Accordion.Body>
                        <p>
                            <strong>Description</strong>
                        </p>
                        <p>
                            <strong>
                                <em>
                                    <img src={WifiService} alt="FREE Unlimited Wi-Fi Service" /> FREE Unlimited Wi-Fi Service</em>
                            </strong>
                        <br />
                        <br />
                        <img src={Bedrooms} alt="Bedroom" /> 1 bedroom apartment with built-in closet, stunning kitchen unit with built-in stove and oven. Prepaid 
                        electricity. Water included. 
                        <br />
                        <img src={Bathroom} alt="Bathroom" /> Bathroom, shower, toilet, basin, bathroom mirror cabinet, and hot water. Has USB plug, DStv plug and play. 
                        <br />
                        <img src={SecureParking} alt="Secure Parking" /> Free parking space, with braai area ideal for friends and family. The apartment is secure, 
                        close to schools, shopping complex, and malls.
                        </p>
                        <p>
                            <strong>Documents Required</strong>
                        </p>
                        <ul>
                            <li>ID Documents/Passport</li>
                            <li>Latest payslip</li>
                            <li>3-6 months banking statement</li>
                        </ul>
                    </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="2">
                    <Accordion.Header>
                        <h4>
                            <span className="color-b">2</span> 
                            <span className="color-d">Bedroom</span> 
                            <span className="color-c">Apartment</span>
                            &nbsp;
                            <sup>
                                <ins>
                                    <small>
                                        x 2 Under Construction
                                    </small>
                                </ins>
                            </sup>
                        </h4>
                        
                    </Accordion.Header>
                    <Accordion.Body>
                        <p>
                            <strong>Description</strong>
                        </p>
                        <p>
                            <strong>
                                <em>
                                    <img src={WifiService} alt="FREE Unlimited Wi-Fi Service" /> FREE Unlimited Wi-Fi Service</em>
                            </strong>
                        <br />
                        <br />
                        <img src={Bedrooms} alt="Bedroom" /> 2 bedroom apartment with built-in closets, stunning kitchen unit with built-in stove and oven and an open space for a 
                        living room. Prepaid electricity. Water included.
                        <br />
                        <img src={Bathroom} alt="Bathroom" /> Bathroom has shower {/*glass door*/}, toilet, basin, bathroom mirror cabinet, and hot shower water. Has USB plug, DStv 
                        plug and play. 
                        <br />
                        <img src={SecureParking} alt="Secure Parking" /> Free parking space, with braai area ideal for friends and family. The apartment is secure, close to schools, 
                        shopping complex, and malls
                        </p>
                        <p>
                            <strong>Documents Required</strong>
                        </p>
                        <ul>
                            <li>ID Documents/Passport</li>
                            <li>Latest payslip</li>
                            <li>3-6 months banking statement</li>
                        </ul>
                    </Accordion.Body>
                </Accordion.Item>

            </Accordion>        
            
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={props.onHide} aria-label="Close">Close</Button>
        </Modal.Footer>
      </Modal>
    );
}

export default function GalleryComponent() {
    
    const [modalShow, setModalShow] = useState(false);
    
    return (
        <div className="container">
            <h1>Gallery</h1>
            <div className="row">
                <div className="col">
                    <h5>Feast your eyes on our range of high class and sophisticated units that we have on offer</h5>                    
                </div>
            </div>
            
            <div className="row m-2">
                <div className="col">
                    <div>
                        We are currently in the construction phase of our project. 
                        <br />
                        Please don't hesitate to book with us for viewing so you don't miss out.
                        <Button type="button" className="btn btn-warning" onClick={() => setModalShow(true)}>Notifications
                         <span className="badge badge-success">5</span>
                        </Button>
                    </div>
                    <MyVerticallyCenteredModal
                        show={modalShow}
                        onHide={() => setModalShow(false)}
                    />
                </div>
            </div>
            
            <div className="row">
                <div className="col">
                    <div className="carousel slide carousel-fade" data-mdb-ride="carousel">
                        <CarouselGalleryComponent slides={slides} />
                        <br />
                    </div>
                </div>
            </div>
            
            <div className="row">
                <div className="col">
                    <h6>You see anything your like? Don't hesitate to get in-touch with us <Link aria-label="Get in-touch" to="/contact-us">here</Link></h6>
                </div>
            </div>
        
            <br />
        </div>
    )
}