import { useEffect, useState } from "react";
import retrieveAllTenantsApi, { deleteTenantApi } from "./api/TMSRestApiService";
import { useAuth } from "./security/AuthContext";
import { useLocation, useNavigate } from "react-router-dom";
import SideBarComponent from "./SideBarComponent";


export default function ListTenantsComponent(props) {

    const authContext = useAuth();

    const navigate = useNavigate();

    const [tenants, setTenants] = useState();
    const tenant_status_active = 0;

    const [message, setMessage] = useState(null);

    const location = useLocation();

    useEffect( () => refreshTenantList(authContext), [authContext] );
    
    function refreshTenantList(authContext) {
        
        retrieveAllTenantsApi(authContext.token)
        .then(
            (response) => {
                setTenants(response.data);
                console.log(response.data);
            }
        )
        .catch(error => console.log(error));

    }

    function deleteTenant(tenant) {
        deleteTenantApi(tenant.id)
        .then(
            //1: Display message
            //2: Update Tenant List
            () => {
                setMessage(`Deletion of Tenant with name ${tenant.name} ${tenant.surname} was successful!`)
                refreshTenantList(authContext)
            }
        )
        .catch(error => console.log(error));
    }

    function updateTenant(tenant) {
        navigate(`/tenant/${tenant.id}`);
    }

    function addNewTenant() {
        navigate(`/tenant/-1`);
    }

    return (
        <div className="container">

            <div className="row">
                <div className="col-3 col-md-2">
                    <SideBarComponent />
                </div>

                <div className="col-15 col-md-10">
                    <div className="row">
                        <h1>List of all Tenants</h1>
                    </div>
                    <div className="row">
                        <h4>
                            Manage all your tenants.    
                        </h4>
                        <h6 className="lead">
                            Your tenants are you most valuable assets in the business, handle with care.
                        </h6>
                    </div>
                    <div className="row">
                        {message && <div className="alert alert-warning">{message}</div>}
                    </div>
                    <div className="row">
                        {location.state && <div className="alert alert-success">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-check-circle-fill" viewBox="0 0 16 16">
                                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
                            </svg>&nbsp;
                            <strong>{location.state.name} {location.state.surname}</strong> details were successfully {location.state.operation}.</div>
                        }
                    </div>
                    <div className="row">
                        <div className="table-responsive">
                            <table className="table table-striped table-hover table-responsive caption-top">
                                <caption>Tenants Info:</caption>                    
                                <thead className="table-dark">
                                    <tr>
                                        <th>Status</th>
                                        <th>Room No.:</th>
                                        <th>Name</th>
                                        <th>E-mail Address</th>
                                        <th>Is Deposit Paid?</th>
                                        <th>Lease End Date</th>
                                        <th colSpan={2}>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    { (tenants && 
                                        (tenants.length == 0)) ? 
                                            (
                                                <tr>
                                                    <td colSpan={8} className="text-danger">No Results</td>
                                                </tr>
                                            )
                                            :
                                            tenants && tenants.map((tenant) => 
                                            (                   
                                                <tr key={tenant.id}>
                                                    <td>{tenant.tenantStatus === tenant_status_active ? 
                                                        (
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-person-fill-check icon-success" viewBox="0 0 16 16">
                                                                <path d="M12.5 16a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7m1.679-4.493-1.335 2.226a.75.75 0 0 1-1.174.144l-.774-.773a.5.5 0 0 1 .708-.708l.547.548 1.17-1.951a.5.5 0 1 1 .858.514M11 5a3 3 0 1 1-6 0 3 3 0 0 1 6 0"/>
                                                                <path d="M2 13c0 1 1 1 1 1h5.256A4.5 4.5 0 0 1 8 12.5a4.5 4.5 0 0 1 1.544-3.393Q8.844 9.002 8 9c-5 0-6 3-6 4"/>
                                                            </svg>) :
                                                        (
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-person-fill-x icon-danger" viewBox="0 0 16 16">
                                                                <path d="M11 5a3 3 0 1 1-6 0 3 3 0 0 1 6 0m-9 8c0 1 1 1 1 1h5.256A4.5 4.5 0 0 1 8 12.5a4.5 4.5 0 0 1 1.544-3.393Q8.844 9.002 8 9c-5 0-6 3-6 4"/>
                                                                <path d="M12.5 16a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7m-.646-4.854.646.647.646-.647a.5.5 0 0 1 .708.708l-.647.646.647.646a.5.5 0 0 1-.708.708l-.646-.647-.646.647a.5.5 0 0 1-.708-.708l.647-.646-.647-.646a.5.5 0 0 1 .708-.708"/>
                                                            </svg>
                                                        )}
                                                    </td>
                                                    <td>
                                                        <abbr title={tenant.roomNumber.toString()} className="initialism">
                                                            {tenant.roomNumber.substring(0, 3)}
                                                        </abbr>
                                                    </td>
                                                    <td>{tenant.name} {tenant.surname}</td>
                                                    <td>{tenant.email}</td>
                                                    <td>
                                                        {tenant.depositPaid.toString() === 'true' ? <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-emoji-smile icon-success" viewBox="0 0 16 16">
                                                            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                                                            <path d="M4.285 9.567a.5.5 0 0 1 .683.183A3.498 3.498 0 0 0 8 11.5a3.498 3.498 0 0 0 3.032-1.75.5.5 0 1 1 .866.5A4.498 4.498 0 0 1 8 12.5a4.498 4.498 0 0 1-3.898-2.25.5.5 0 0 1 .183-.683zM7 6.5C7 7.328 6.552 8 6 8s-1-.672-1-1.5S5.448 5 6 5s1 .672 1 1.5zm4 0c0 .828-.448 1.5-1 1.5s-1-.672-1-1.5S9.448 5 10 5s1 .672 1 1.5z"/>
                                                            </svg>   : 

                                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-emoji-frown icon-danger" viewBox="0 0 16 16">
                                                            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                                                            <path d="M4.285 12.433a.5.5 0 0 0 .683-.183A3.498 3.498 0 0 1 8 10.5c1.295 0 2.426.703 3.032 1.75a.5.5 0 0 0 .866-.5A4.498 4.498 0 0 0 8 9.5a4.5 4.5 0 0 0-3.898 2.25.5.5 0 0 0 .183.683zM7 6.5C7 7.328 6.552 8 6 8s-1-.672-1-1.5S5.448 5 6 5s1 .672 1 1.5zm4 0c0 .828-.448 1.5-1 1.5s-1-.672-1-1.5S9.448 5 10 5s1 .672 1 1.5z"/>
                                                            </svg>
                                                        }
                                                    </td>
                                                    <td>{tenant.leaseEndDate}</td>
                                                    <td>
                                                        <svg aria-label="Update tenant" onClick={ () => updateTenant(tenant) } xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-pencil-fill text-warning pointer-link" viewBox="0 0 16 16">
                                                            <path d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.5.5 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11z"/>
                                                        </svg>
                                                    </td>
                                                    <td>
                                                        <svg aria-label="Delete tenant" onClick={ () => deleteTenant(tenant) } xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-trash3-fill text-danger pointer-link" viewBox="0 0 16 16">
                                                            <path d="M11 1.5v1h3.5a.5.5 0 0 1 0 1h-.538l-.853 10.66A2 2 0 0 1 11.115 16h-6.23a2 2 0 0 1-1.994-1.84L2.038 3.5H1.5a.5.5 0 0 1 0-1H5v-1A1.5 1.5 0 0 1 6.5 0h3A1.5 1.5 0 0 1 11 1.5m-5 0v1h4v-1a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5M4.5 5.029l.5 8.5a.5.5 0 1 0 .998-.06l-.5-8.5a.5.5 0 1 0-.998.06m6.53-.528a.5.5 0 0 0-.528.47l-.5 8.5a.5.5 0 0 0 .998.058l.5-8.5a.5.5 0 0 0-.47-.528M8 4.5a.5.5 0 0 0-.5.5v8.5a.5.5 0 0 0 1 0V5a.5.5 0 0 0-.5-.5"/>
                                                        </svg>
                                                    </td>
                                                </tr>
                                            )
                                        ) 
                                    }

                                </tbody>
                                <tfoot className="table-dark">
                                    <tr>
                                        <td colSpan={8}><h6><small>Table Data &copy;</small></h6></td>
                                    </tr>
                                </tfoot>
                            </table>
                        </div>
                        <div className="row">
                            <div className="col">
                                <button aria-label="Add new tenant" className="btn btn-success m-3" onClick={addNewTenant}>
                                    Add New Tenant
                                </button>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>            
        </div>
    )
}