import Carousel from 'react-bootstrap/Carousel';

export default function CarouselTestimonialsComponent({loadSlides}) {
    return (
        <Carousel data-bs-theme="dark">
            {loadSlides.map((slide) => (
                <Carousel.Item key={slide.tenant}>                    
                    <img
                    className={slide.style}
                    src={slide.image}
                    alt={slide.alternateText}
                    />
                     
                    <Carousel.Caption>
                        <h4 className='whiteText'>{slide.comment}</h4>
                        <h5 className='whiteText'>{slide.tenant}</h5>
                    </Carousel.Caption>
                </Carousel.Item>                 
            ))}
        </Carousel>
    )
}