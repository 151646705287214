import { apiClient } from "./ApiClient";

export function executeBasicAuthenticationApiService(token) {
    return apiClient.get('/tenants/basicauth', {
        headers: {
            Authorization: token
        }
    });
}

export function executeJWTAuthenticationApiService(username, password) {
    return apiClient.post('/authenticate', {username, password})
}