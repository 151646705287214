import { ErrorMessage, Field, Form, Formik } from "formik";
import SideBarComponent from "./SideBarComponent";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { createTicketApi, retrieveTicketByIdApi, updateTicketApi } from "./api/SupportTicketApiService";
import getTimeStamp, { generateRandomSixDigit } from "./utils/commonUtility";
import { Popover } from 'bootstrap/dist/js/bootstrap.esm.min.js';

export default function TicketComponent() {

    let navigate = useNavigate();

    let {username} = useParams();
    let {id} = useParams();

    let [category, setCategory] = useState('');
    let [title, setTitle] = useState('');
    let [description, setDescription] = useState('');
    let [status, setStatus] = useState('');
    let [priority, setPriority] = useState('');
    let [comments, setComments] = useState('');
    let [ticketNumber, setTicketNumber] = useState('');
    let [raisedBy, setRaisedBy] = useState('');
    let [dateRaised, setDateRaised] = useState('');

    useEffect(
        () =>  retrieveTickets(id), [id],
                Array.from(document.querySelectorAll('button[data-bs-toggle="popover"]'))
                    .forEach(popoverNode => new Popover(popoverNode))        
    )

    function retrieveTickets(id) {
        if (id != -1) {
            retrieveTicketByIdApi(id)
            .then(response => {
                setRaisedBy(response.data.raisedBy)
                setDateRaised(response.data.dateRaised)
                setTitle(response.data.title)
                setDescription(response.data.description)
                setCategory(response.data.category)
                setStatus(response.data.status)
                setTicketNumber(response.data.ticketNumber)
                setPriority(response.data.priority)
                setComments(response.data.comments)
            })
            .catch(error => console.log(error))
        }
    }

    function onSubmit(values) {
        ticketNumber = (id != -1) ? ticketNumber : generateRandomSixDigit();
        dateRaised = (id != -1) ? dateRaised : getTimeStamp();
        status = (id == -1) ? "OPEN" : values.status;
        raisedBy = username;

        let ticket = {
            id: id,
            ticketNumber: ticketNumber,
            dateRaised: dateRaised,
            raisedBy: raisedBy,
            title: values.title,
            description: values.description,
            priority: values.priority,
            category: values.category,
            status: status,
            comments: values.comments
        };

        if (id == -1) {
            createTicketApi(ticket)
            .then(response => navigate(`/tickets/${username}`, {
                state: {
                    isCreateAlertVisible: true,
                    ticketNumber: ticket.ticketNumber,
                    operation: "created"
                }
            }))
            .catch(error => console.log(error))
        } else {
            updateTicketApi(id, ticket)
            .then(response => navigate(`/tickets/${username}`, {
                state: {
                    isUpdateAlertVisible: true,
                    ticketNumber: ticket.ticketNumber,
                    operation: "updated"
                }
            }))
            .catch(error => console.log(error))
        }
    }

    function validate(values) {
        let errors = {};

        if (values.category === null || values.category ==='') {
            errors.category = "Under which category does your ticket fall?";
        }

        if (values.title.length < 2) {
            errors.title = "Enter at least 2 characters for the ticket Title!";
        }

        if (values.description.length < 2) {
            errors.description = "Enter at least 2 characters for the ticket Description!";
        }

        if (values.priority === null || values.priority ==='') {
            errors.priority = "Under which priority does your ticket fall?";
        }

        if (values.comments === null || values.comments === '') {
            errors.comments = "Enter at least 2 characters as part of your comments!";
        }

        return errors;
    }


    function backToTicketList() {
        navigate('/tickets/Mtshepiro');
    }

    return (
        <div className="container">
            <div className="row">
                
                <div className="col-3 col-md-2">
                    <SideBarComponent />
                </div>

                <div className="col-15 col-md-10">
                    <div className="row">
                        <h1>Ticket Information</h1>
                        <p className="lead">
                            Raise a support ticket for assistance.
                        </p>
                        {(id != -1) 
                            ? 
                                <h6>
                                    Ticket Number: 
                                    <button type="button" 
                                        className="btn btn-warning me-2" 
                                        data-bs-container="body" 
                                        data-bs-toggle="popover" 
                                        data-bs-placement="right" 
                                        title="Ticket Info"
                                        data-bs-content="We treat tickets as per the priority that is assigned to them at all times.">
                                            #{ticketNumber}
                                    </button>
                                </h6>
                            : 
                                null
                        }
                        
                    </div>

                    <div className="row m-4">
                        <span className="text-danger font-weight-bold">
                            (*) Indicates a Required Field.
                        </span>
                    </div>

                    <div className="row">

                        <Formik initialValues={ {category, title, description, comments, status, priority }}
                            enableReinitialize = {true}
                            onSubmit={onSubmit}
                            validate={validate}
                        >

                        {
                            (props) => (

                                <Form>
                                    <ErrorMessage
                                        name="category"
                                        component="div"
                                        className="alert alert-danger"
                                    />

                                    <ErrorMessage
                                        name="title"
                                        component="div"
                                        className="alert alert-danger"
                                    />

                                    <ErrorMessage
                                        name="description"
                                        component="div"
                                        className="alert alert-danger"
                                    />

                                    <ErrorMessage
                                        name="priority"
                                        component="div"
                                        className="alert alert-danger"
                                    />

                                    <ErrorMessage
                                        name="comments"
                                        component="div"
                                        className="alert alert-danger"
                                    />

                                    <div className="table-responsive">
                                        <table className="table table-responsive caption-top">
                                            <tbody>
                                            <tr className="form-group">
                                                <td>
                                                    <label htmlFor="status">Status:</label>
                                                </td>
                                                    { (id == -1) 
                                                        ?
                                                            <td>
                                                                <Field type="text" id="status" className="form-control hs-input" name="status" value="OPEN" disabled />
                                                            </td>
                                                        : 
                                                            <td>
                                                                <Field as="select" id="status" className="form-control hs-input" name="status">  
                                                                    <option value="OPEN" className="text-primary">Open</option>
                                                                    <option value="WORK_IN_PROGRESS" className="text-warning">Work in progress</option>
                                                                    <option value="COMPLETED" className="text-success">Completed</option>
                                                                    <option value="CLOSED" className="text-danger">Closed</option>
                                                                </Field>
                                                            </td>
                                                    }
                                            </tr>
                                            <tr className="form-group">
                                                <td>
                                                    <label htmlFor="category">Select Category:</label>
                                                    <span className="text-danger font-weight-bold">*</span>
                                                </td>
                                                <td>
                                                    <Field as="select" id="category" className="form-control hs-input" name="category">
                                                        <option value="">Please Select...</option>
                                                        <option value="BASIC_MAINTENANCE">Basic Maintenance</option>
                                                        <option value="CARPENTRY">Carpentry</option>
                                                        <option value="ELECTRICAL">Electrical</option>
                                                        <option value="PAINTING">Painting</option>
                                                        <option value="PLUMBING">Plumbing</option>
                                                        <option value="WIFI">Wi-Fi</option>
                                                    </Field>
                                                </td>
                                            </tr>
                                            <tr className="form-group">
                                                <td>
                                                    <label htmlFor="title">Title:</label>
                                                    <span className="text-danger font-weight-bold">*</span>
                                                </td>
                                                <td>
                                                    <Field type="text" id="title" className="form-control" name="title" />
                                                </td>
                                            </tr>
                                            <tr className="form-group">
                                                <td>
                                                    <label htmlFor="description">Description:</label>
                                                    <span className="text-danger font-weight-bold">*</span>
                                                </td>
                                                <td>
                                                    <Field as="textarea"
                                                        className="form-control hs-input"
                                                        name="description"
                                                        rows={4}
                                                        cols={40}
                                                        id="description"
                                                    />
                                                </td>
                                            </tr>
                                            <tr className="form-group">
                                                <td>
                                                    <label htmlFor="priority">Priority:</label>
                                                </td>
                                                <td>
                                                    <Field as="select" id="priority" className="form-control hs-input" name="priority">
                                                        <option value="">Please Select...</option>
                                                        <option value="LOW">Low</option>
                                                        <option value="INFORMATION">Information</option>
                                                        <option value="MEDIUM">Medium</option>
                                                        <option value="CRITICAL">Critical</option>
                                                        <option value="HIGH">High</option>
                                                    </Field>
                                                </td>
                                            </tr>
                                            <tr className="form-group">
                                                <td>
                                                    <label htmlFor="comments">Comments:</label>
                                                    <span className="text-danger font-weight-bold">*</span>
                                                </td>
                                                <td>
                                                    <Field as="textarea"
                                                        className="form-control hs-input"
                                                        name="comments"
                                                        rows={4}
                                                        cols={40}
                                                        id="comments"
                                                    />
                                                </td>
                                            </tr>

                                            <tr>
                                                <td>
                                                    <button aria-label="Submit Ticket" className="btn btn-success" type="submit">Submit Ticket</button>
                                                </td>
                                                <td>
                                                    <button aria-label="Back to Ticket List" className="btn btn-primary" onClick={backToTicketList}>Back to Ticket Lists</button>
                                                </td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </Form>                                
                            )
                        }  
                        </Formik>                      
                    </div>
                </div>
            </div>
        </div>
    )
}