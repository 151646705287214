import { Link } from "react-router-dom";

export default function ErrorComponent() {
    return (
        <div className="ErrorComponent">
            <h1>We are working really hard!</h1>
            <div>
                Apologies for the 404.
                <br />
                Our Support Department are standing by for any assistance that you may require.
                <br />
                Please get in touch with us <Link to="/contact-us">here</Link>.
            </div>
        </div>
    )
}