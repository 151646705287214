export default function getTimeStamp() {
    const PREFIX_ZERO = "0";
    var now = new Date();
    return (
        (now.getFullYear()) + '-' +
            (
                (now.getMonth() < 10) 
                    ? (PREFIX_ZERO + (now.getMonth() + 1))
                    : ((now.getMonth() + 1))
            ) 
            + '-' +
            (
                (now.getDate() < 10)
                    ? (PREFIX_ZERO + (now.getDate()))
                    : ((now.getDate()))
             ) 
             + "T" +
             (
                (now.getHours() < 10)
                    ? (PREFIX_ZERO + (now.getHours()))
                    : ((now.getHours()))
            ) 
            + ':' +
            (
                (now.getMinutes() < 10)
                    ? (PREFIX_ZERO + now.getMinutes())
                    : (now.getMinutes())
            ) 
            + ':' +
            (
                (now.getSeconds() < 10)
                    ? (PREFIX_ZERO + now.getSeconds())
                    : (now.getSeconds())
            )
        );
}

export function emailValidator(email) {
    return (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email));
}

export function generateRandomSixDigit() {
    return Math.floor(Math.random() * (999999 - 10000 + 1)) + 10000;
}

export function formateDateTimeByRemovingT(dateTime) {
    return dateTime.replace('T', ' ');
}

export function replaceAllUnderScores(underScore) {
    return underScore.replaceAll('_', ' ');
}