import CarouselTestimonialsComponent from './CarouselTestimonialsComponent';
import loadSlides from './mockTestimonials.json';

export default function TestimonialsComponent() {
    return (
        <div className="container">
            <h1>Testimonials</h1>
            <div className='m-3'>
                <h3>What our tenants say about us:</h3>
            </div>
            <div>
                <CarouselTestimonialsComponent loadSlides={loadSlides} />
                <br />
                <br />
            </div>
        </div>
    )
}