export default function SubmitTestimonialComponent() {
    return (
        <div className="container">
            <div className="row">
                <h1>
                    Leave a Testimonial
                </h1>
            </div>
        </div>
    )
}