import underConstruction from './images/underconstruction.jpg';

export default function GiftsComponent() {
    return (
        <div className="container">
            <div className="row">
                <h1>Gifts</h1>
            </div>
            <div className="row">
                <h4>Spread the Love and give a Gift to your loved ones:</h4>
                <h6 className="lead">
                    We introduce a nice and fancy way to help you stay in touch with your loved 
                    ones helping them get their needs met while improving their stay with us.    
                </h6>
            </div>
            <div className="row">
                <img src={underConstruction} aria-label='Under Construction' alt="Under Construction" />
            </div>
            
        </div>
    );
}