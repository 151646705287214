import SideBarComponent from "./SideBarComponent";
import underConstruction from "./images/underconstruction.jpg";

export default function AccountsComponent() {

    return (
        <div className="container">

            <div className="row">
                <div className="col-3 col-md-2">
                    <SideBarComponent />
                </div>

                <div className="col-15 col-md-10">
                    <div className="row">
                        <h1>Accounts</h1>
                    </div>
                    <div className="row">
                        <h4>Features:</h4>
                        <h6 className="lead">
                            You can manage your account here, which will offer you practical benefits such as 
                            to safeguard access to your private account by blocking out unauthorised third 
                            parties which typically requires a username and password for authentication and 
                            is used by a single person.    
                        </h6>
                    </div>
                    <div className="row">
                        <img src={underConstruction} aria-label="Under Construction" alt="Under Construction" />
                    </div>
                </div>
            </div>
        </div>
    )
}