import { Link } from "react-router-dom";
import { useAuth } from "./security/AuthContext";
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { NavDropdown } from "react-bootstrap";

export default function HeaderComponent() {

    const authContext = useAuth();
    const isAuthenticated = authContext.isAuthenticated;

    function logout() {
        authContext.logout();
    }

    return (
        <Navbar expand="lg" className="headerMenuBackgroundColor sticky-top">
            <Container>
                <Navbar.Brand href="https://www.tltproduction.com" className="navbar-brand ms-2 fs-2 fw-bold text-white">
                    { isAuthenticated ? <span className="header-title">TMS</span> : <span className="header-title">TLT</span> }-<span className="first-header-title">Properties</span></Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" className="header-nav-dropdown-menu"/>
                        <Navbar.Collapse id="basic-navbar-nav">
                            <Nav className="me-auto">
                                { isAuthenticated ? <Link aria-label="Dashboard" className="nav-link text-white" to="/dashboard/Mtshepiro">Dashboard</Link> : <Link aria-label="Home" className="nav-link text-white" to="/welcome">Home</Link> }
                                { isAuthenticated ? <Link aria-label="My account" className="nav-link text-white" to="/accounts/Mtshepiro">Accounts</Link> : <Link aria-label="About us" className="nav-link text-white" to="/about-us">About Us</Link> }
                                { isAuthenticated && <Link aria-label="My properties" className="nav-link text-white" to="/properties/Mtshepiro">Properties</Link> }
                                { isAuthenticated ? <Link aria-label="Galleries" className="nav-link text-white" to="/galleries/Mtshepiro">Galleries</Link> : <Link aria-label="Gallery" className="nav-link text-white" to="/gallery">Gallery</Link> }
                                { isAuthenticated && <Link aria-label="Tickets" className="nav-link text-white" to="/tickets/Mtshepiro">Tickets</Link> }
                                { isAuthenticated && <Link aria-label="Tenants" className="nav-link text-white" to="/tenants/Mtshepiro">Tenants</Link> }
                                { isAuthenticated ? <Link aria-label="Reports" className="nav-link text-white" to="/reports/Mtshepiro">Reports</Link> : <Link aria-label="Testimonials" className="nav-link text-white" to="/testimonials">Testimonials</Link> }
                                { isAuthenticated ? <Link aria-label="Contacts" className="nav-link text-white" to="/contacts/Mtshepiro">Contacts</Link> : <Link aria-label="Contact us" className="nav-link text-white" to="/contact-us">Contact Us</Link> }
                            </Nav>
                            <Nav>
                                <NavDropdown
                                    id="nav-dropdown-dark-example"
                                    title="Menu"
                                    bsPrefix="text-white no-underlined"
                                >
                                    { isAuthenticated && 
                                        <NavDropdown.Item href="/accounts/Mtshepiro">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-person-circle" viewBox="0 0 16 16">
                                                <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0"/>
                                                <path fillRule="evenodd" d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8m8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1"/>
                                            </svg>&nbsp;My Account
                                        </NavDropdown.Item>
                                    }

                                    { !isAuthenticated &&
                                        <NavDropdown.Item href="/sign-up">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-person-plus" viewBox="0 0 16 16">
                                            <path d="M6 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6m2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0m4 8c0 1-1 1-1 1H1s-1 0-1-1 1-4 6-4 6 3 6 4m-1-.004c-.001-.246-.154-.986-.832-1.664C9.516 10.68 8.289 10 6 10s-3.516.68-4.168 1.332c-.678.678-.83 1.418-.832 1.664z"/>
                                            <path fill-rule="evenodd" d="M13.5 5a.5.5 0 0 1 .5.5V7h1.5a.5.5 0 0 1 0 1H14v1.5a.5.5 0 0 1-1 0V8h-1.5a.5.5 0 0 1 0-1H13V5.5a.5.5 0 0 1 .5-.5"/>
                                        </svg>&nbsp;Sign Up
                                        </NavDropdown.Item>
                                    }

                                    { !isAuthenticated &&
                                        <NavDropdown.Item href="/sign-in">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-box-arrow-in-right" viewBox="0 0 16 16">
                                                <path fillRule="evenodd" d="M6 3.5a.5.5 0 0 1 .5-.5h8a.5.5 0 0 1 .5.5v9a.5.5 0 0 1-.5.5h-8a.5.5 0 0 1-.5-.5v-2a.5.5 0 0 0-1 0v2A1.5 1.5 0 0 0 6.5 14h8a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2h-8A1.5 1.5 0 0 0 5 3.5v2a.5.5 0 0 0 1 0z"/>
                                                <path fillRule="evenodd" d="M11.854 8.354a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H1.5a.5.5 0 0 0 0 1h8.793l-2.147 2.146a.5.5 0 0 0 .708.708z"/>
                                            </svg>&nbsp;Sign In
                                        </NavDropdown.Item>
                                    }
                                    
                                    { !isAuthenticated &&
                                        <NavDropdown.Item href="/forgot-password">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-shield-lock" viewBox="0 0 16 16">
                                            <path d="M5.338 1.59a61 61 0 0 0-2.837.856.48.48 0 0 0-.328.39c-.554 4.157.726 7.19 2.253 9.188a10.7 10.7 0 0 0 2.287 2.233c.346.244.652.42.893.533q.18.085.293.118a1 1 0 0 0 .101.025 1 1 0 0 0 .1-.025q.114-.034.294-.118c.24-.113.547-.29.893-.533a10.7 10.7 0 0 0 2.287-2.233c1.527-1.997 2.807-5.031 2.253-9.188a.48.48 0 0 0-.328-.39c-.651-.213-1.75-.56-2.837-.855C9.552 1.29 8.531 1.067 8 1.067c-.53 0-1.552.223-2.662.524zM5.072.56C6.157.265 7.31 0 8 0s1.843.265 2.928.56c1.11.3 2.229.655 2.887.87a1.54 1.54 0 0 1 1.044 1.262c.596 4.477-.787 7.795-2.465 9.99a11.8 11.8 0 0 1-2.517 2.453 7 7 0 0 1-1.048.625c-.28.132-.581.24-.829.24s-.548-.108-.829-.24a7 7 0 0 1-1.048-.625 11.8 11.8 0 0 1-2.517-2.453C1.928 10.487.545 7.169 1.141 2.692A1.54 1.54 0 0 1 2.185 1.43 63 63 0 0 1 5.072.56"/>
                                            <path d="M9.5 6.5a1.5 1.5 0 0 1-1 1.415l.385 1.99a.5.5 0 0 1-.491.595h-.788a.5.5 0 0 1-.49-.595l.384-1.99a1.5 1.5 0 1 1 2-1.415"/>
                                        </svg>&nbsp;Forgot Password
                                        </NavDropdown.Item>
                                    }                                     

                                    { isAuthenticated && 
                                        <NavDropdown.Item href="/logout" onClick={logout}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-box-arrow-left" viewBox="0 0 16 16">
                                                <path fillRule="evenodd" d="M6 12.5a.5.5 0 0 0 .5.5h8a.5.5 0 0 0 .5-.5v-9a.5.5 0 0 0-.5-.5h-8a.5.5 0 0 0-.5.5v2a.5.5 0 0 1-1 0v-2A1.5 1.5 0 0 1 6.5 2h8A1.5 1.5 0 0 1 16 3.5v9a1.5 1.5 0 0 1-1.5 1.5h-8A1.5 1.5 0 0 1 5 12.5v-2a.5.5 0 0 1 1 0z"/>
                                                <path fillRule="evenodd" d="M.146 8.354a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L1.707 7.5H10.5a.5.5 0 0 1 0 1H1.707l2.147 2.146a.5.5 0 0 1-.708.708z"/>
                                            </svg>&nbsp;Logout
                                        </NavDropdown.Item>
                                    }

                                    { isAuthenticated &&
                                        <NavDropdown.Item href="/gifts">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-gift" viewBox="0 0 16 16">
                                                <path d="M3 2.5a2.5 2.5 0 0 1 5 0 2.5 2.5 0 0 1 5 0v.006c0 .07 0 .27-.038.494H15a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1v7.5a1.5 1.5 0 0 1-1.5 1.5h-11A1.5 1.5 0 0 1 1 14.5V7a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h2.038A2.968 2.968 0 0 1 3 2.506zm1.068.5H7v-.5a1.5 1.5 0 1 0-3 0c0 .085.002.274.045.43a.522.522 0 0 0 .023.07M9 3h2.932a.56.56 0 0 0 .023-.07c.043-.156.045-.345.045-.43a1.5 1.5 0 0 0-3 0zM1 4v2h6V4zm8 0v2h6V4zm5 3H9v8h4.5a.5.5 0 0 0 .5-.5zm-7 8V7H2v7.5a.5.5 0 0 0 .5.5z"/>
                                            </svg>&nbsp;Gifts
                                        </NavDropdown.Item>
                                    }

                                    { isAuthenticated &&
                                        <NavDropdown.Divider />
                                    }

                                    { isAuthenticated &&
                                        <NavDropdown.Item href="/shopping-cart">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-cart3" viewBox="0 0 16 16">
                                                <path d="M0 1.5A.5.5 0 0 1 .5 1H2a.5.5 0 0 1 .485.379L2.89 3H14.5a.5.5 0 0 1 .49.598l-1 5a.5.5 0 0 1-.465.401l-9.397.472L4.415 11H13a.5.5 0 0 1 0 1H4a.5.5 0 0 1-.491-.408L2.01 3.607 1.61 2H.5a.5.5 0 0 1-.5-.5M3.102 4l.84 4.479 9.144-.459L13.89 4H3.102zM5 12a2 2 0 1 0 0 4 2 2 0 0 0 0-4m7 0a2 2 0 1 0 0 4 2 2 0 0 0 0-4m-7 1a1 1 0 1 1 0 2 1 1 0 0 1 0-2m7 0a1 1 0 1 1 0 2 1 1 0 0 1 0-2"/>
                                            </svg>&nbsp;Shopping Cart
                                        </NavDropdown.Item>
                                    }
                                </NavDropdown>
                            </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>

    );
}