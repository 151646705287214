import { apiClient } from "./ApiClient";

export default function retrieveAllTicketsApi(token) {
    return apiClient.get('/tickets/find/all');
}

export function retrieveTicketByIdApi(id) {
    return apiClient.get(`/tickets/find/by/${id}`);
}

export function deleteTicketApi(id) {
    return apiClient.delete(`/tickets/delete/${id}`);
}

export function updateTicketApi(id, ticket) {
    return apiClient.put(`/tickets/update/${id}`, ticket);
}

export function createTicketApi(ticket) {
    return apiClient.post(`/tickets/create`, ticket);
}