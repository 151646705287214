import { useState } from "react";
import { Link } from "react-router-dom";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import FreeUnlimitedWifiConnection from './images/FreeUnlimitedWifiConnection.jpg';
import StunningKitchenUnits from './images/StunningKitchenUnits.jpg';
import BuiltInClosets from './images/BuiltInClosets.jpg';
import SafeAndSecureAccommodation from "./images/SafeAndSecureAccommodation.jpg";

function ModalShowFreeUnlimitedWiFi(props) {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Free Unlimited Wi-Fi Connection
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>

            <div>
                <img className="img-fluid rounded" loading="lazy" src={FreeUnlimitedWifiConnection} alt="Free Unlimited Wi-Fi Connection" />
            </div>
            <h4>Unlimited Data</h4>
            <p>
                Unlimited Data. All our packages are Unlimited and Unthrottled data packages. 
                We do not shape, implement quality of service or contention rations. PURE INTERNET.
            </p>

            <h4>Network</h4>
            <p>
            ikeja owns and builds it's own bespoke wireless infrastructure for townships in South Africa. 
            </p>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={props.onHide}>Close</Button>
        </Modal.Footer>
      </Modal>
    );
  }

  function ModalShowStunningKitchenUnits(props) {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Stunning Kitchen Units
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <div>
                <img className="img-fluid rounded" loading="lazy" src={StunningKitchenUnits} alt="Stunning Kitchen Units" />
            </div>
            <div>
                <h4>Modern Kitchen Units</h4>
            </div>
            <div>
                <p>
                    With minimalist cabinetry, neutral color palettes, and metallic accents, modern kitchen ideas 
                    are worth pursuing if you crave subtle elegance that will never go out of style. These spaces 
                    blend aspects of contemporary design with other styles, including traditional and industrial, 
                    for a look that's current, sleek, yet not sterile. Curious about modern kitchen looks? We're 
                    here to help you get started with everything you need to know.
                </p>
            </div>
          
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={props.onHide}>Close</Button>
        </Modal.Footer>
      </Modal>
    );
  }

  
  function ModalShowBuiltInClosets(props) {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Built-in Closets
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <div>
                <img className="img-fluid rounded" loading="lazy" src={BuiltInClosets} alt="Built-in Closets" />
            </div>
            <div>
                <h4>Key Features</h4>
            </div>
            <div>
                <ul>
                    <li>
                        Neutral color palettes paired with rich wood tones and primary accents
                    </li>
                    <li>
                        Supermatte surfaces and slab door and drawer fronts with simple lines
                    </li>
                    <li>
                        Fascia with lighting, sliding doors, Aluminum accents, and minimal accessories 
                        and decorative elements; push-to-open feature requires no external hardware
                    </li>
                </ul>
            </div>
          
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={props.onHide}>Close</Button>
        </Modal.Footer>
      </Modal>
    );
  }

  function ModalShowSafeAndSecureAccommodation(props) {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Safe and Secure Accommodation
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <div>
                <img className="img-fluid rounded mx-auto d-block" loading="lazy" src={SafeAndSecureAccommodation} alt="Safe and Secure Accommodation" />
            </div>
            <div>
                <h4>Your Safety and Security is our Concern</h4>
            </div>
            <div>                
                <p>
                    Safety & Security, as far as we can, are part of what we do for our Residents. 
                    We want them to live in a safe, happy, homely environment with abundant friends. 
                    This will only be possible if we have the Safety & Security of our residents at heart.
                    <br />
                    We have high-tech, modern, state-of-the-art cameras in many communal areas inside the Home 
                    (and in some strategic areas outside the home).
                </p>
            </div>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={props.onHide}>Close</Button>
        </Modal.Footer>
      </Modal>
    );
  }


export default function FooterComponent() {

    const [modalShowBuiltInClosets, setModalShowBuiltInClosets] = useState(false);
    const [modalShowFreeUnlimitedWiFi, setModalShowFreeUnlimitedWiFi] = useState(false);
    const [modalShowSafeAndSecureAccommodation, setModalShowSafeAndSecureAccommodation] = useState(false);
    const [modalShowStunningKitchenUnits, setModalShowStunningKitchenUnits] = useState(false);

    function getDate() {
        const year = new Date();
        return year.getFullYear();
    }


    return (
      <div className="row">
        <div className="container">
          <div className="row">
            <div className="col-12">

            <footer className="text-center text-lg-start text-dark">
              <section className="d-flex justify-content-between p-4 text-white backgroundColorFooter">
              <div className="me-5">
                <span>Get connected with us on social networks:</span>
              </div>

              <div>
                <a aria-label="facebook" href="https://www.facebook.com" className="text-white me-4">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-facebook" viewBox="0 0 16 16">
                    <path d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951z"/>
                  </svg>
                </a>


                <a aria-label="twitter" href="https://www.twitter.com" className="text-white me-4">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-twitter" viewBox="0 0 16 16">
                    <path d="M5.026 15c6.038 0 9.341-5.003 9.341-9.334 0-.14 0-.282-.006-.422A6.685 6.685 0 0 0 16 3.542a6.658 6.658 0 0 1-1.889.518 3.301 3.301 0 0 0 1.447-1.817 6.533 6.533 0 0 1-2.087.793A3.286 3.286 0 0 0 7.875 6.03a9.325 9.325 0 0 1-6.767-3.429 3.289 3.289 0 0 0 1.018 4.382A3.323 3.323 0 0 1 .64 6.575v.045a3.288 3.288 0 0 0 2.632 3.218 3.203 3.203 0 0 1-.865.115 3.23 3.23 0 0 1-.614-.057 3.283 3.283 0 0 0 3.067 2.277A6.588 6.588 0 0 1 .78 13.58a6.32 6.32 0 0 1-.78-.045A9.344 9.344 0 0 0 5.026 15z"/>
                  </svg>                
                </a>


                <a aria-label="google" href="https://www.google.co.za/search?q=tltproduction&sca_esv=591956038&sxsrf=AM9HkKnwWDgYTqdX-mAVR6_R-XK6YLRBAA%3A1702930185536&source=hp&ei=CaeAZYW2Hom7hbIPy7qQyAM&iflsig=AO6bgOgAAAAAZYC1Ga44x60Z_gt2QJ6ximZYVwI53wmz&oq=tlt&gs_lp=Egdnd3Mtd2l6IgN0bHQqAggAMgQQIxgnMgQQIxgnMgQQIxgnMgoQABiABBiKBRhDMgoQABiABBiKBRhDMgsQLhivARjHARiABDILEC4YgAQYxwEYrwEyCxAAGIAEGLEDGIMBMgsQLhivARjHARiABDIFEAAYgARI0Q9QAFiFAXAAeACQAQCYAYEDoAHbB6oBBTItMS4yuAEByAEA-AEBwgIKECMYgAQYigUYJ8ICEBAuGIAEGIoFGMcBGNEDGCfCAhYQLhiABBiKBRhDGLEDGIMBGMcBGNEDwgIQEAAYgAQYigUYQxixAxiDAcICDRAAGIAEGIoFGEMYsQPCAhAQLhiABBiKBRhDGLEDGIMBwgIIEAAYgAQYsQPCAgsQLhiABBixAxiDAQ&sclient=gws-wiz#ip=1" className="text-white me-4">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-google" viewBox="0 0 16 16">
                    <path d="M15.545 6.558a9.42 9.42 0 0 1 .139 1.626c0 2.434-.87 4.492-2.384 5.885h.002C11.978 15.292 10.158 16 8 16A8 8 0 1 1 8 0a7.689 7.689 0 0 1 5.352 2.082l-2.284 2.284A4.347 4.347 0 0 0 8 3.166c-2.087 0-3.86 1.408-4.492 3.304a4.792 4.792 0 0 0 0 3.063h.003c.635 1.893 2.405 3.301 4.492 3.301 1.078 0 2.004-.276 2.722-.764h-.003a3.702 3.702 0 0 0 1.599-2.431H8v-3.08h7.545z"/>
                  </svg>
                </a>


                <a aria-label="instagram" href="https://www.instagram.com/tshepo_m1011/" className="text-white me-4">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-instagram" viewBox="0 0 16 16">
                    <path d="M8 0C5.829 0 5.556.01 4.703.048 3.85.088 3.269.222 2.76.42a3.917 3.917 0 0 0-1.417.923A3.927 3.927 0 0 0 .42 2.76C.222 3.268.087 3.85.048 4.7.01 5.555 0 5.827 0 8.001c0 2.172.01 2.444.048 3.297.04.852.174 1.433.372 1.942.205.526.478.972.923 1.417.444.445.89.719 1.416.923.51.198 1.09.333 1.942.372C5.555 15.99 5.827 16 8 16s2.444-.01 3.298-.048c.851-.04 1.434-.174 1.943-.372a3.916 3.916 0 0 0 1.416-.923c.445-.445.718-.891.923-1.417.197-.509.332-1.09.372-1.942C15.99 10.445 16 10.173 16 8s-.01-2.445-.048-3.299c-.04-.851-.175-1.433-.372-1.941a3.926 3.926 0 0 0-.923-1.417A3.911 3.911 0 0 0 13.24.42c-.51-.198-1.092-.333-1.943-.372C10.443.01 10.172 0 7.998 0h.003zm-.717 1.442h.718c2.136 0 2.389.007 3.232.046.78.035 1.204.166 1.486.275.373.145.64.319.92.599.28.28.453.546.598.92.11.281.24.705.275 1.485.039.843.047 1.096.047 3.231s-.008 2.389-.047 3.232c-.035.78-.166 1.203-.275 1.485a2.47 2.47 0 0 1-.599.919c-.28.28-.546.453-.92.598-.28.11-.704.24-1.485.276-.843.038-1.096.047-3.232.047s-2.39-.009-3.233-.047c-.78-.036-1.203-.166-1.485-.276a2.478 2.478 0 0 1-.92-.598 2.48 2.48 0 0 1-.6-.92c-.109-.281-.24-.705-.275-1.485-.038-.843-.046-1.096-.046-3.233 0-2.136.008-2.388.046-3.231.036-.78.166-1.204.276-1.486.145-.373.319-.64.599-.92.28-.28.546-.453.92-.598.282-.11.705-.24 1.485-.276.738-.034 1.024-.044 2.515-.045v.002zm4.988 1.328a.96.96 0 1 0 0 1.92.96.96 0 0 0 0-1.92zm-4.27 1.122a4.109 4.109 0 1 0 0 8.217 4.109 4.109 0 0 0 0-8.217zm0 1.441a2.667 2.667 0 1 1 0 5.334 2.667 2.667 0 0 1 0-5.334z"/>
                  </svg>
                </a>


                <a aria-label="linkedin" href="https://za.linkedin.com" className="text-white me-4">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-linkedin" viewBox="0 0 16 16">
                    <path d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854V1.146zm4.943 12.248V6.169H2.542v7.225h2.401zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248-.822 0-1.359.54-1.359 1.248 0 .694.521 1.248 1.327 1.248h.016zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016a5.54 5.54 0 0 1 .016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225h2.4z"/>
                  </svg>
                </a>


                <a aria-label="github" href="https://github.com" className="text-white me-4">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-github" viewBox="0 0 16 16">
                    <path d="M8 0C3.58 0 0 3.58 0 8c0 3.54 2.29 6.53 5.47 7.59.4.07.55-.17.55-.38 0-.19-.01-.82-.01-1.49-2.01.37-2.53-.49-2.69-.94-.09-.23-.48-.94-.82-1.13-.28-.15-.68-.52-.01-.53.63-.01 1.08.58 1.23.82.72 1.21 1.87.87 2.33.66.07-.52.28-.87.51-1.07-1.78-.2-3.64-.89-3.64-3.95 0-.87.31-1.59.82-2.15-.08-.2-.36-1.02.08-2.12 0 0 .67-.21 2.2.82.64-.18 1.32-.27 2-.27.68 0 1.36.09 2 .27 1.53-1.04 2.2-.82 2.2-.82.44 1.1.16 1.92.08 2.12.51.56.82 1.27.82 2.15 0 3.07-1.87 3.75-3.65 3.95.29.25.54.73.54 1.48 0 1.07-.01 1.93-.01 2.2 0 .21.15.46.55.38A8.012 8.012 0 0 0 16 8c0-4.42-3.58-8-8-8z"/>
                  </svg>
                </a>
            </div>
            </section>

            <section className="instagramBackgroundColor">
              <div className="container text-center text-md-start">
                <div className="row">
                  <div className="col-md-3 col-lg-4 col-xl-3 mx-auto mb-4">
                    <h6 className="text-uppercase fw-bold color-c">TLT-Properties</h6>
                    <hr className="mb-4 mt-0 d-inline-block mx-auto companyBackgroundColor" />
                    <p className="text-white">
                    Where great service meets customer expectation and beyond. We pride ourselves
                    with convenience, class, and lifestyle living in a secured environment. The 
                    TLT-Properties is in pursuit of symbolising natural growth and sustainability, 
                    symbolising the company's focus on a healthy future for South Africa.
                    </p>
                  </div>

                  <div className="col-md-2 col-lg-2 col-xl-2 mx-auto mb-4">
                    <h6 className="text-uppercase fw-bold color-c">Products</h6>
                    <hr className="mb-4 mt-0 d-inline-block mx-auto productsBackgroundColor" />
                    <p>
                        <a aria-label="Free unlimited Wi-Fi connection" href="#!" onClick={() => setModalShowFreeUnlimitedWiFi(true)} className="text-dark text-white">Free Unlimited Wi-Fi Connection</a>
                    </p>
                    <p>
                        <a aria-label="Stunning kitchen units" href="#!"  onClick={() => setModalShowStunningKitchenUnits(true)} className="text-dark text-white">Stunning Kitchen Units</a>
                    </p>
                    <p>
                        <a aria-label="Built-in closets" href="#!"  onClick={() => setModalShowBuiltInClosets(true)} className="text-dark text-white">Built-in Closets</a>
                    </p>
                    <p>
                        <a aria-label="Safe and secure accommodation" href="#!"  onClick={() => setModalShowSafeAndSecureAccommodation(true)} className="text-dark text-white">Safe and Secure Accommodation</a>
                    </p>

                    <ModalShowFreeUnlimitedWiFi show={modalShowFreeUnlimitedWiFi} onHide={() => setModalShowFreeUnlimitedWiFi(false)} />

                    <ModalShowBuiltInClosets show={modalShowBuiltInClosets} onHide={() => setModalShowBuiltInClosets(false)} />
                    
                    <ModalShowStunningKitchenUnits show={modalShowStunningKitchenUnits} onHide={() => setModalShowStunningKitchenUnits(false)} />
                    
                    <ModalShowSafeAndSecureAccommodation show={modalShowSafeAndSecureAccommodation} onHide={() => setModalShowSafeAndSecureAccommodation(false)} />
                  </div>

                  <div className="col-md-3 col-lg-2 col-xl-2 mx-auto mb-4">
                    <h6 className="text-uppercase fw-bold color-c">Useful links</h6>
                    <hr className="mb-4 mt-0 d-inline-block mx-auto usefulLinkBackgroundColor" />
                    <p>
                        <Link aria-label="Your account" to="/sign-in" className="text-dark text-white">Your Account</Link>
                    </p>
                    <p>
                        <Link aria-label="Become an affiliate" to="/contact-us" className="text-dark text-white">Become an Affiliate</Link>
                    </p>
                    <p>
                        <Link aria-label="About us" to="/about-us" className="text-dark text-white">About Us</Link>
                    </p>
                    <p>
                        <Link aria-label="Help" to="/contact-us" className="text-dark text-white">Help</Link>
                    </p>
                  </div>

                  <div className="col-md-4 col-lg-3 col-xl-3 mx-auto mb-md-0 mb-4">
                    <h6 className="text-uppercase fw-bold color-c">Contact</h6>
                    <hr className="mb-4 mt-0 d-inline-block mx-auto contactUsBackgroundColor" />
                    <p className="text-white">
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-house-fill" viewBox="0 0 16 16">
                        <path d="M8.707 1.5a1 1 0 0 0-1.414 0L.646 8.146a.5.5 0 0 0 .708.708L8 2.207l6.646 6.647a.5.5 0 0 0 .708-.708L13 5.793V2.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v1.293L8.707 1.5Z"/>
                        <path d="m8 3.293 6 6V13.5a1.5 1.5 0 0 1-1.5 1.5h-9A1.5 1.5 0 0 1 2 13.5V9.293l6-6Z"/>
                      </svg>&nbsp;
                        57 Caledon Drive, Extension 4, Norkem Park, Kempton Park, 1618, Johannesburg, Gauteng Province,  South Africa.
                    </p>
                    
                    <p className="text-white">
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-envelope-fill" viewBox="0 0 16 16">
                        <path d="M.05 3.555A2 2 0 0 1 2 2h12a2 2 0 0 1 1.95 1.555L8 8.414.05 3.555ZM0 4.697v7.104l5.803-3.558L0 4.697ZM6.761 8.83l-6.57 4.027A2 2 0 0 0 2 14h12a2 2 0 0 0 1.808-1.144l-6.57-4.027L8 9.586l-1.239-.757Zm3.436-.586L16 11.801V4.697l-5.803 3.546Z"/>
                      </svg>&nbsp;
                      <a href="mailto:info@tltproduction.com" className="text-white">info@tltproduction.com</a>
                    </p>


                    <p className="text-white">
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-telephone-fill" viewBox="0 0 16 16">
                        <path fillRule="evenodd" d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z"/>
                      </svg>&nbsp;
                      <a href="tel:0812086672" className="text-white link-offset-2 link-underline link-underline-opacity-0">(+27) 81 208 6672</a>
                    </p>

                    <p className="text-white">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-whatsapp" viewBox="0 0 16 16">
                        <path d="M13.601 2.326A7.854 7.854 0 0 0 7.994 0C3.627 0 .068 3.558.064 7.926c0 1.399.366 2.76 1.057 3.965L0 16l4.204-1.102a7.933 7.933 0 0 0 3.79.965h.004c4.368 0 7.926-3.558 7.93-7.93A7.898 7.898 0 0 0 13.6 2.326zM7.994 14.521a6.573 6.573 0 0 1-3.356-.92l-.24-.144-2.494.654.666-2.433-.156-.251a6.56 6.56 0 0 1-1.007-3.505c0-3.626 2.957-6.584 6.591-6.584a6.56 6.56 0 0 1 4.66 1.931 6.557 6.557 0 0 1 1.928 4.66c-.004 3.639-2.961 6.592-6.592 6.592zm3.615-4.934c-.197-.099-1.17-.578-1.353-.646-.182-.065-.315-.099-.445.099-.133.197-.513.646-.627.775-.114.133-.232.148-.43.05-.197-.1-.836-.308-1.592-.985-.59-.525-.985-1.175-1.103-1.372-.114-.198-.011-.304.088-.403.087-.088.197-.232.296-.346.1-.114.133-.198.198-.33.065-.134.034-.248-.015-.347-.05-.099-.445-1.076-.612-1.47-.16-.389-.323-.335-.445-.34-.114-.007-.247-.007-.38-.007a.729.729 0 0 0-.529.247c-.182.198-.691.677-.691 1.654 0 .977.71 1.916.81 2.049.098.133 1.394 2.132 3.383 2.992.47.205.84.326 1.129.418.475.152.904.129 1.246.08.38-.058 1.171-.48 1.338-.943.164-.464.164-.86.114-.943-.049-.084-.182-.133-.38-.232z"/>
                      </svg>&nbsp;
                      <a href="tel:0812086672" className="text-white link-offset-2 link-underline link-underline-opacity-0">(+27) 81 208 6672</a>
                    </p>
                  </div>
                </div>
            </div>
            </section>

            <div className="text-center p-4 text-white backgroundColorFooter">
              &copy; { getDate() } Copyright:
              <a aria-label="TLT-Properties" className="text-white" href="https://www.tltproduction.com">TLT-<span className="color-c">Properties</span></a>
            </div>
          </footer>

        
          </div>
        </div>
      </div>
    </div>        
  )
}