import underConstruction from './images/underconstruction.jpg';

export default function ShoppingCartComponent() {
    return (
        <div className="container">
            <div className="row">
                <h1>Shopping Cart</h1>
            </div>
            <div className="row">
                <h4>Shop with us:</h4>
                <h6 className="lead">
                    We bring you a variety of homeware furniture and appliances to improve your stay with us.    
                </h6>
            </div>
            <div className="row">
                <img src={underConstruction} alt='Under Construction' aria-label='Under Construction' />
            </div>
        </div>
    );
}