import underConstruction from "./images/underconstruction.jpg";
import SideBarComponent from "./SideBarComponent";

export default function GalleriesComponent() {

    return (
        <div className="container">

            <div className="row">
                <div className="col-3 col-md-2">
                    <SideBarComponent />
                </div>

                <div className="col-15 col-md-10">
                    <div className="row">
                        <h1>Galleries</h1>
                    </div>
                    <div className="row">
                        <h4>Manage your Galleries:</h4>
                        <h6 className="lead">
                            Your List of galleries for your apartmens at one central place for an overview.    
                        </h6>
                    </div>
                    <div className="row">
                        <img src={underConstruction} aria-label="Under Construction" alt="Under Construction" />
                    </div>
                </div>
            </div>
        </div>
    )
}