import { useParams } from "react-router-dom";
import underConstruction from "./images/underconstruction.jpg";
import SideBarComponent from "./SideBarComponent";

export default function AboutUsManagementComponent() {
    const {username} = useParams();

    return (
        <div className="container">

            <div className="row">
                <div className="col-3 col-md-2">
                    <SideBarComponent />
                </div>

                <div className="col-15 col-md-10">
                    <div className="row">
                        <h1>About The Management</h1>
                    </div>
                    <div className="row">
                        <h6>
                            User Profile: <button className="btn btn-primary">{username}</button>    
                        </h6>
                    </div>
                    <div className="row">
                        <img src={underConstruction} aria-label="Under Construction" alt="Under Construction" />
                    </div>
                </div>
            </div>
        </div>
    )
}