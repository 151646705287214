import underConstruction from './images/underconstruction.jpg';

export default function RegistrationComponent() {
    return (
        <div className="container">
            <div className="row">
                <h1>Sign Up</h1>
            </div>
            <div className="row">
                <img src={underConstruction} aria-label='Under Construction' alt='Under Construction' />
            </div>            
        </div>
    );
}
