import underConstruction from './images/underconstruction.jpg';

export default function ForgotPasswordComponent() {
    return (
        <div className="container">
            <div className="row">
                <h1>Forgot Password</h1>
            </div>
            <div className="row">
                <h4>Let's help you get your login credentials back:</h4>
                <h6 className="lead">
                    Forgetting your password can be a stressful event, but worry not, we got your back ;-).    
                </h6>
            </div>
            <div className="row">
                <img src={underConstruction} aria-label='Under Construction' alt='Under Construction' />
            </div>            
        </div>
    );
}
