import { Link, useParams } from "react-router-dom";
import SideBarComponent from "./SideBarComponent";
import ProductionSupportTicket from "./images/production-support-ticket.png";
import ProductionSupportTickets from "./images/production-support-tickets.png";
import ProductionSupportTicketSeach from "./images/production-support-ticket-search.jpg";

export default function MaintenanceComponent() {
    
    const {username} = useParams();
    const viewTickets = "/tickets/" + username;
    const raiseTicket = "/ticket/" + username + "/-1";
    const searchTickets = "/tickets/" + username;

    return (
        <div className="container">
            <div className="row">
                <div className="col-3 col-md-2">
                    <SideBarComponent />
                </div>
                <div className="col-15 col-md-10">
                    <div className="row">
                        <h1>Maintenance</h1>
                    </div>
                    <div className="row">
                        <h4>Manage your Tasks:</h4>
                        <h6 className="lead">
                            Manage all your tasks in one central place for ease of navigation and maintenance of the site, tenants and its users.
                        </h6>
                    </div>
                    <div className="row">
                        <div className="col">
                            <Link aria-label="View My Tickets" to={viewTickets} className="nav-link">
                                <div className="card">                                
                                    <img className="card-img-top" src={ProductionSupportTickets} alt="View all Production Support Tickets" />
                                        <div className="card-body">
                                            <h5 className="card-title">My Tickets</h5>
                                            <p className="card-text">This card will take you to the list of tickets page where you can get a snapshot view of all your logged tickets.</p>
                                            <p className="card-text"><small className="text-muted">Last updated 3 mins ago</small></p>
                                        </div>
                                </div>
                            </Link>
                        </div>

                        <div className="col">
                            <Link aria-label="Raise a Ticket" to={raiseTicket} className="no-underlined">
                                <div className="card">
                                    <img className="card-img-top" src={ProductionSupportTicket} alt="View a Production Support Ticket" />
                                        <div className="card-body">
                                            <h5 className="card-title">Raise a Ticket</h5>
                                            <p className="card-text">Do you have an issue that you'd like to log? Use this card to raise a ticket so you can get the assistance that you require for your peace of mind and our team of experts will sort you out.</p>
                                            <p className="card-text"><small className="text-muted">Last updated 3 mins ago</small></p>
                                        </div>
                                </div>
                            </Link>
                        </div>

                        <div className="col">
                            <Link aria-label="Search for a Ticket" to={viewTickets} className="no-underlined">
                                <div className="card">
                                    <img className="card-img-top" src={ProductionSupportTicketSeach} alt="Search for a Production Support Ticket" />
                                        <div className="card-body">
                                            <h5 className="card-title">Search for a Ticket</h5>
                                            <p className="card-text">If you have a reference number for your ticket and would like to get the status of you ticket, we got you covered in that regard.</p>
                                            <p className="card-text"><small className="text-muted">Last updated 3 mins ago</small></p>
                                        </div>
                                    </div>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}