import { Formik, Form, Field, ErrorMessage } from "formik";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { createContactUsApi } from "./api/TMSRestApiService";
import ReCAPTCHA from "react-google-recaptcha";
import React, { useRef, useState } from "react";
import { useAuth } from "./security/AuthContext";
import emailjs from '@emailjs/browser';

export default function ContactUsComponent() {
    
    const navigate = useNavigate();

    const location = useLocation();

    let username = 'Mtshepiro';
    let password = 'dummy';

    const form = useRef();

    const authContext = useAuth();

    const [reCaptcha, setReCaptcha] = useState(null);

    function onSubmit(values, actions) {

        authContext.login(username, password);
        
        const contactUs = {
            firstName: values.firstName,
            lastName: values.lastName,
            emailAddress: values.emailAddress,
            mobilePhoneNumber: values.mobilePhoneNumber,
            whereDidYouHearAboutUs: values.whereDidYouHearAboutUs,
            message: values.message,
            acceptTermsAndConditions: values.acceptTermsAndConditions
        };

        const serviceID = "service_ef6gu2h";
        const templateID = "template_tel8jqi";

        createContactUsApi(contactUs)
        .then(response => {

            emailjs.sendForm(serviceID, templateID, form.current, {
                publicKey: 'gP8IeCYb6yTSrxzw7',
            })
                    
            console.log('SUCCESS! ', response.status, response.text);
            actions.resetForm();
            authContext.logout();
            navigate('/contact-us', {
                state: {
                    firstName: contactUs.firstName,
                    lastName: contactUs.lastName,
                    success: 'Email sent successfully!'    
                }
            });
        })
        .catch(error => console.log('FAILED...', error));
    }

    function validate(values) {
        let errors = {};

        if(values.firstName.length < 2) {
            errors.firstName = "Please enter at least 2 characters for your First Name!";
        }

        if (values.lastName.length < 2) {
            errors.lastName = "Please enter at least 2 characters for your Last Name!";
        }

        if (values.mobilePhoneNumber.length !== 10) {
            errors.mobilePhoneNumber = "Mobile Phone Number must be 10 digits long!";
        }

        if (!values.emailAddress) {
            errors.emailAddress = "Email Address is Required!";
        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.emailAddress)) {
            errors.emailAddress = 'Invalid email address';
        }

        if (values.whereDidYouHearAboutUs === 'Please Select...') {
            errors.whereDidYouHearAboutUs = "Please tell us where you heard about us?";
        }

        return errors;
    }

    return (
        <div className="container">
            <h1>Contact Us</h1>
            <div className="m-4">
                Get in touch with us: <span className="text-danger font-weight-bold">(*)</span> Indicates a Required Field.
            </div>

            <Formik
                initialValues={ {firstName: '', lastName: '', emailAddress: '', mobilePhoneNumber: '', message: '', whereDidYouHearAboutUs: '', acceptTermsAndConditions: ''} }
                enableReinitialize={true}
                onSubmit={onSubmit} 
                validate={validate}
            >
                {
                    (props) => (
                        <Form ref={form}>

                            {location.state && <div className="alert alert-success">Thank you <strong>{location.state.firstName} {location.state.lastName}</strong>&nbsp;
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-emoji-wink" viewBox="0 0 16 16">
  <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"/>
  <path d="M4.285 9.567a.5.5 0 0 1 .683.183A3.498 3.498 0 0 0 8 11.5a3.498 3.498 0 0 0 3.032-1.75.5.5 0 1 1 .866.5A4.498 4.498 0 0 1 8 12.5a4.498 4.498 0 0 1-3.898-2.25.5.5 0 0 1 .183-.683M7 6.5C7 7.328 6.552 8 6 8s-1-.672-1-1.5S5.448 5 6 5s1 .672 1 1.5m1.757-.437a.5.5 0 0 1 .68.194.934.934 0 0 0 .813.493c.339 0 .645-.19.813-.493a.5.5 0 1 1 .874.486A1.934 1.934 0 0 1 10.25 7.75c-.73 0-1.356-.412-1.687-1.007a.5.5 0 0 1 .194-.68z"/>
</svg>.&nbsp;
                             {location.state.success}
<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-envelope-at" viewBox="0 0 16 16">&nbsp;
  <path d="M2 2a2 2 0 0 0-2 2v8.01A2 2 0 0 0 2 14h5.5a.5.5 0 0 0 0-1H2a1 1 0 0 1-.966-.741l5.64-3.471L8 9.583l7-4.2V8.5a.5.5 0 0 0 1 0V4a2 2 0 0 0-2-2zm3.708 6.208L1 11.105V5.383zM1 4.217V4a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v.217l-7 4.2z"/>
  <path d="M14.247 14.269c1.01 0 1.587-.857 1.587-2.025v-.21C15.834 10.43 14.64 9 12.52 9h-.035C10.42 9 9 10.36 9 12.432v.214C9 14.82 10.438 16 12.358 16h.044c.594 0 1.018-.074 1.237-.175v-.73c-.245.11-.673.18-1.18.18h-.044c-1.334 0-2.571-.788-2.571-2.655v-.157c0-1.657 1.058-2.724 2.64-2.724h.04c1.535 0 2.484 1.05 2.484 2.326v.118c0 .975-.324 1.39-.639 1.39-.232 0-.41-.148-.41-.42v-2.19h-.906v.569h-.03c-.084-.298-.368-.63-.954-.63-.778 0-1.259.555-1.259 1.4v.528c0 .892.49 1.434 1.26 1.434.471 0 .896-.227 1.014-.643h.043c.118.42.617.648 1.12.648Zm-2.453-1.588v-.227c0-.546.227-.791.573-.791.297 0 .572.192.572.708v.367c0 .573-.253.744-.564.744-.354 0-.581-.215-.581-.8Z"/>
</svg>.</div>}

                            <ErrorMessage
                                name="success"
                                component="div"
                                className="alert alert-success collapse"
                                id="myAlert"
                            />

                            <ErrorMessage
                                name="firstName"
                                component="div"
                                className="alert alert-danger"
                            />

                            <ErrorMessage
                                name="lastName"
                                component="div"
                                className="alert alert-danger"
                            />

                            <ErrorMessage
                                name="mobilePhoneNumber"
                                component="div"
                                className="alert alert-danger"
                            />

                            <ErrorMessage
                                name="emailAddress"
                                component="div"
                                className="alert alert-danger"
                            />

                            <ErrorMessage
                                name="whereDidYouHearAboutUs"
                                component="div"
                                className="alert alert-danger"
                            />
                            
                            <div className="table-responsive">
                                <table className="table table-responsive caption-top">
                                    <tbody>
                                        <tr className="form-group">
                                            <td><label htmlFor="firstName">First name</label><span className="text-danger font-weight-bold">*</span></td>
                                            <td><Field className="form-control hs-input" type="text" name="firstName" /></td>
                                        </tr>
                                        <tr className="form-group">
                                            <td><label htmlFor="lastName">Last name</label><span className="text-danger font-weight-bold">*</span></td>
                                            <td><Field className="form-control hs-input" type="text" name="lastName" /></td>
                                        </tr>
                                        <tr className="form-group">
                                            <td><label htmlFor="mobilePhoneNumber">Mobile phone number</label><span className="text-danger font-weight-bold">*</span></td>
                                            <td><Field className="form-control hs-input" type="text" name="mobilePhoneNumber" /></td>
                                        </tr>
                                        <tr className="form-group">
                                            <td><label htmlFor="emailAddress">Email Address</label><span className="text-danger font-weight-bold">*</span></td>
                                            <td><Field className="form-control hs-input" type="text" name="emailAddress" /></td>
                                        </tr>
                                        <tr className="form-group">
                                            <td><label htmlFor="whereDidYouHearAboutUs">Where did you hear about TMS Properties</label><span className="text-danger font-weight-bold">*</span></td>
                                            <td>
                                                <Field as="select" className="form-control hs-input" name="whereDidYouHearAboutUs">
                                                    <option value="Please Select...">Please Select...</option>
                                                    <option value="BILLBOARD">BILLBOARD</option>
                                                    <option value="ONLINE_SEARCH">ONLINE SEARCH</option>
                                                    <option value="SOCIAL_MEDIA">SOCIAL MEDIA</option>
                                                    <option value="TV">TV</option>
                                                    <option value="RADIO">RADIO</option>
                                                    <option value="PRINT_AD">PRINT AD</option>
                                                    <option value="WORD_OF_MOUTH">WORD OF MOUTH</option>
                                                </Field>
                                            </td>
                                        </tr>
                                        <tr className="form-group">
                                            <td><label htmlFor="message">Message:</label></td>
                                            <td>
                                                <Field as="textarea"
                                                    className="form-control hs-input"
                                                    name="message"
                                                    rows={4}
                                                    cols={40}
                                                />
                                            </td>
                                        </tr>
                                        <tr className="form-group">
                                            <td><label htmlFor="acceptTermsAndConditions">I accept the Terms and Conditions</label>
                                                <span className="text-danger font-weight-bold">*</span>
                                            </td>
                                            <td><Field className="hs-input" type="checkbox" name="acceptTermsAndConditions" /></td>
                                        </tr>
                                        <tr>
                                            <td></td>
                                            <td>
                                                <ReCAPTCHA sitekey="6LfAAt8oAAAAAC5rfcIjYWNoeiik7CmRgCeVYch3" onChange={(value) => setReCaptcha(value)} />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colSpan={2}>
                                                <button aria-label="Submit contact-us form" id="btnSubmit" className="btn btn-success m-5" type="submit" disabled={!reCaptcha}>Submit</button>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </Form>                        
                    )
                }
            </Formik>
            <div className="row txt-left">
                <div className="col-sm-3">                
                    <h3>JOHANNESBUG</h3>
                    <div>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-house-fill" viewBox="0 0 16 16">
                        <path d="M8.707 1.5a1 1 0 0 0-1.414 0L.646 8.146a.5.5 0 0 0 .708.708L8 2.207l6.646 6.647a.5.5 0 0 0 .708-.708L13 5.793V2.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v1.293L8.707 1.5Z"/>
                        <path d="m8 3.293 6 6V13.5a1.5 1.5 0 0 1-1.5 1.5h-9A1.5 1.5 0 0 1 2 13.5V9.293l6-6Z"/>
                    </svg>&nbsp;
                        <strong>Physical Address</strong>                        
                    </div>
                    <div>
                        57 Caledon Drive,
                        <br />
                        Extension 4,
                        <br />
                        Norkem Park,
                        <br />
                        Kempton Park,
                        <br />
                        1618
                        <br />
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-telephone-fill" viewBox="0 0 16 16">
                            <path fillRule="evenodd" d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z"/>
                        </svg> 
                        &nbsp;
                        <a href="tel:0812086672" className="color-black link-offset-2 link-underline link-underline-opacity-0">(+27) 81 208 6672</a> 
                        &nbsp; 
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-whatsapp" viewBox="0 0 16 16">
                            <path d="M13.601 2.326A7.854 7.854 0 0 0 7.994 0C3.627 0 .068 3.558.064 7.926c0 1.399.366 2.76 1.057 3.965L0 16l4.204-1.102a7.933 7.933 0 0 0 3.79.965h.004c4.368 0 7.926-3.558 7.93-7.93A7.898 7.898 0 0 0 13.6 2.326zM7.994 14.521a6.573 6.573 0 0 1-3.356-.92l-.24-.144-2.494.654.666-2.433-.156-.251a6.56 6.56 0 0 1-1.007-3.505c0-3.626 2.957-6.584 6.591-6.584a6.56 6.56 0 0 1 4.66 1.931 6.557 6.557 0 0 1 1.928 4.66c-.004 3.639-2.961 6.592-6.592 6.592zm3.615-4.934c-.197-.099-1.17-.578-1.353-.646-.182-.065-.315-.099-.445.099-.133.197-.513.646-.627.775-.114.133-.232.148-.43.05-.197-.1-.836-.308-1.592-.985-.59-.525-.985-1.175-1.103-1.372-.114-.198-.011-.304.088-.403.087-.088.197-.232.296-.346.1-.114.133-.198.198-.33.065-.134.034-.248-.015-.347-.05-.099-.445-1.076-.612-1.47-.16-.389-.323-.335-.445-.34-.114-.007-.247-.007-.38-.007a.729.729 0 0 0-.529.247c-.182.198-.691.677-.691 1.654 0 .977.71 1.916.81 2.049.098.133 1.394 2.132 3.383 2.992.47.205.84.326 1.129.418.475.152.904.129 1.246.08.38-.058 1.171-.48 1.338-.943.164-.464.164-.86.114-.943-.049-.084-.182-.133-.38-.232z"/>
                        </svg>
                        <br />
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-globe2" viewBox="0 0 16 16">
                            <path d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm7.5-6.923c-.67.204-1.335.82-1.887 1.855-.143.268-.276.56-.395.872.705.157 1.472.257 2.282.287V1.077zM4.249 3.539c.142-.384.304-.744.481-1.078a6.7 6.7 0 0 1 .597-.933A7.01 7.01 0 0 0 3.051 3.05c.362.184.763.349 1.198.49zM3.509 7.5c.036-1.07.188-2.087.436-3.008a9.124 9.124 0 0 1-1.565-.667A6.964 6.964 0 0 0 1.018 7.5h2.49zm1.4-2.741a12.344 12.344 0 0 0-.4 2.741H7.5V5.091c-.91-.03-1.783-.145-2.591-.332zM8.5 5.09V7.5h2.99a12.342 12.342 0 0 0-.399-2.741c-.808.187-1.681.301-2.591.332zM4.51 8.5c.035.987.176 1.914.399 2.741A13.612 13.612 0 0 1 7.5 10.91V8.5H4.51zm3.99 0v2.409c.91.03 1.783.145 2.591.332.223-.827.364-1.754.4-2.741H8.5zm-3.282 3.696c.12.312.252.604.395.872.552 1.035 1.218 1.65 1.887 1.855V11.91c-.81.03-1.577.13-2.282.287zm.11 2.276a6.696 6.696 0 0 1-.598-.933 8.853 8.853 0 0 1-.481-1.079 8.38 8.38 0 0 0-1.198.49 7.01 7.01 0 0 0 2.276 1.522zm-1.383-2.964A13.36 13.36 0 0 1 3.508 8.5h-2.49a6.963 6.963 0 0 0 1.362 3.675c.47-.258.995-.482 1.565-.667zm6.728 2.964a7.009 7.009 0 0 0 2.275-1.521 8.376 8.376 0 0 0-1.197-.49 8.853 8.853 0 0 1-.481 1.078 6.688 6.688 0 0 1-.597.933zM8.5 11.909v3.014c.67-.204 1.335-.82 1.887-1.855.143-.268.276-.56.395-.872A12.63 12.63 0 0 0 8.5 11.91zm3.555-.401c.57.185 1.095.409 1.565.667A6.963 6.963 0 0 0 14.982 8.5h-2.49a13.36 13.36 0 0 1-.437 3.008zM14.982 7.5a6.963 6.963 0 0 0-1.362-3.675c-.47.258-.995.482-1.565.667.248.92.4 1.938.437 3.008h2.49zM11.27 2.461c.177.334.339.694.482 1.078a8.368 8.368 0 0 0 1.196-.49 7.01 7.01 0 0 0-2.275-1.52c.218.283.418.597.597.932zm-.488 1.343a7.765 7.765 0 0 0-.395-.872C9.835 1.897 9.17 1.282 8.5 1.077V4.09c.81-.03 1.577-.13 2.282-.287z"/>
                        </svg>&nbsp;
                        <Link to="https://tltproduction.com" className="link-success">tltproduction.com</Link>
                    </div>
                </div>
                <div className="col-sm-9">
                    <div className="google-map">
                        <iframe title="Google_Map_57_Caledon" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3584.7678268970403!2d28.21500111127784!3d-26.041151577082164!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1e956b7075f53757%3A0xd49e8d1a3736206d!2s57%20Caledon%20Dr%2C%20Norkem%20Park%2C%20Kempton%20Park%2C%201618!5e0!3m2!1sen!2sza!4v1697644270041!5m2!1sen!2sza" width="600" height="450" style={{border:0}} allowFullScreen="" loading="async" referrerPolicy="no-referrer-when-downgrade"></iframe>
                    </div>
                </div>
            </div>
            <br />            
        </div>
    )
}