import './App.css';
import TMSApp from './components/tms/TMSApp';

function App() {
  return (
    <div className="App">
      <TMSApp />
    </div>
  );
}

export default App;