import Carousel from 'react-bootstrap/Carousel';

function CarouselComponent({slides}) {
    
    return (

        <Carousel data-bs-theme="dark">
            {slides.map((slide) => (
                <Carousel.Item key={slide.image}>
                    <img
                    className={slide.style}
                    src={slide.image}
                    alt={slide.alternateText}
                    />
                    <Carousel.Caption>
                        <h5 className='whiteText'>{slide.title}</h5>
                        <p className='whiteText'>{slide.subTitle}</p>
                    </Carousel.Caption>
                </Carousel.Item>                 
            ))}
        </Carousel>
            
    )
}
export default CarouselComponent;