import { defaults} from "chart.js/auto";
import { Bar, Doughnut, Line, Radar } from "react-chartjs-2";

import revenueData from "./chartData/revenueData.json";
import sourceData from "./chartData/sourceData.json";
import radarData1st from "./chartData/radarData1st.json";
import radarData2nd from "./chartData/radarData2nd.json";
import SideBarComponent from "./SideBarComponent";
import { useParams } from "react-router-dom";
import { useAuth } from "./security/AuthContext";

defaults.plugins.title.display = true;
defaults.plugins.title.align = "start";
defaults.plugins.title.font.size = 20;
defaults.plugins.title.color = "black";

export default function DashboardComponent() {
    
    const {username} = useParams();

    const authContext = useAuth();
    const isAuthenticated = authContext.isAuthenticated;

    return (
        <div className="container">
            <div className="row">

                <div className="col-3 col-md-2">
                    <SideBarComponent />
                </div>           

                <div className="col-15 col-md-10">
                    
                    <div className="row mb-3">
                        <h1>Dashboard</h1>  
                        <h2><em>Welcome</em> { isAuthenticated && <em className="color-c">{username}</em> }</h2>
                        <h6 className="lead">
                            The <strong><u>TMS-Properties Dashboard</u></strong> offers <b><em>business intelligence (BI)</em></b> reporting tools that 
                            aggregate and display critical metrics and key performance indicators (KPIs) in a 
                            single screen, thus enabling users to monitor and examine business performance at a glance.
                        </h6>
                    </div>   

                    <div className="row mb-5">
                        <div className="col">
                            <div className="card">
                                <div className="card-body">
                                    <h5 className="card-title">Sales</h5>
                                    <div className="d-flex align-items-center">
                                        <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
                                            <p className="card-text">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" fill="currentColor" className="bi bi-cart" viewBox="0 0 16 16">
                                                    <path d="M0 1.5A.5.5 0 0 1 .5 1H2a.5.5 0 0 1 .485.379L2.89 3H14.5a.5.5 0 0 1 .491.592l-1.5 8A.5.5 0 0 1 13 12H4a.5.5 0 0 1-.491-.408L2.01 3.607 1.61 2H.5a.5.5 0 0 1-.5-.5M3.102 4l1.313 7h8.17l1.313-7zM5 12a2 2 0 1 0 0 4 2 2 0 0 0 0-4m7 0a2 2 0 1 0 0 4 2 2 0 0 0 0-4m-7 1a1 1 0 1 1 0 2 1 1 0 0 1 0-2m7 0a1 1 0 1 1 0 2 1 1 0 0 1 0-2"/>
                                                </svg>
                                            </p>
                                        </div>
                                        <div className="ps-3">
                                            <h6>145</h6>
                                            <span className="text-success small pt-1 fw-bold">12%</span> <span className="text-muted small pt-2 ps-1">increase</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-footer">
                                    <small className="text-muted">Last updated 3 mins ago</small>
                                </div>
                            </div>
                        </div>
                        
                        <div className="col">
                            <div className="card revenue-card">
                                <div className="card-body">
                                    <h5 className="card-title">Revenue</h5>
                                    <div className="d-flex align-items-center">
                                        <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
                                            <p className="card-text">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" fill="currentColor" className="bi bi-currency-dollar" viewBox="0 0 16 16">
                                                    <path d="M4 10.781c.148 1.667 1.513 2.85 3.591 3.003V15h1.043v-1.216c2.27-.179 3.678-1.438 3.678-3.3 0-1.59-.947-2.51-2.956-3.028l-.722-.187V3.467c1.122.11 1.879.714 2.07 1.616h1.47c-.166-1.6-1.54-2.748-3.54-2.875V1H7.591v1.233c-1.939.23-3.27 1.472-3.27 3.156 0 1.454.966 2.483 2.661 2.917l.61.162v4.031c-1.149-.17-1.94-.8-2.131-1.718zm3.391-3.836c-1.043-.263-1.6-.825-1.6-1.616 0-.944.704-1.641 1.8-1.828v3.495l-.2-.05zm1.591 1.872c1.287.323 1.852.859 1.852 1.769 0 1.097-.826 1.828-2.2 1.939V8.73z"/>
                                                </svg>
                                            </p>
                                        </div>
                                        <div className="ps-3">
                                            <h6>R3,264.00</h6>
                                            <span className="text-success small pt-1 fw-bold">8%</span> <span className="text-muted small pt-2 ps-1">increase</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-footer">
                                    <small className="text-muted">Last updated 6 mins ago</small>
                                </div>
                            </div>
                        </div>
                    
                        <div className="col">
                            <div className="card tenants-card">
                                <div className="card-body">
                                    <h5 className="card-title">Tenants</h5>
                                    <div className="d-flex align-items-center">
                                        <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
                                            <p className="card-text">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" fill="currentColor" className="bi bi-people" viewBox="0 0 16 16">
                                                    <path d="M15 14s1 0 1-1-1-4-5-4-5 3-5 4 1 1 1 1zm-7.978-1L7 12.996c.001-.264.167-1.03.76-1.72C8.312 10.629 9.282 10 11 10c1.717 0 2.687.63 3.24 1.276.593.69.758 1.457.76 1.72l-.008.002-.014.002zM11 7a2 2 0 1 0 0-4 2 2 0 0 0 0 4m3-2a3 3 0 1 1-6 0 3 3 0 0 1 6 0M6.936 9.28a6 6 0 0 0-1.23-.247A7 7 0 0 0 5 9c-4 0-5 3-5 4q0 1 1 1h4.216A2.24 2.24 0 0 1 5 13c0-1.01.377-2.042 1.09-2.904.243-.294.526-.569.846-.816M4.92 10A5.5 5.5 0 0 0 4 13H1c0-.26.164-1.03.76-1.724.545-.636 1.492-1.256 3.16-1.275ZM1.5 5.5a3 3 0 1 1 6 0 3 3 0 0 1-6 0m3-2a2 2 0 1 0 0 4 2 2 0 0 0 0-4"/>
                                                </svg>
                                            </p>
                                        </div>
                                        <div className="ps-3">
                                            <h6>1244</h6>
                                            <span className="text-danger small pt-1 fw-bold">5%</span> <span className="text-muted small pt-2 ps-1">decrease</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-footer">
                                    <small className="text-muted">Last updated 10 mins ago</small>
                                </div>
                            </div>
                        </div>

                        <div className="col">
                            <div className="card completed-tasks-card">
                                <div className="card-body">
                                    <h5 className="card-title">Completed Tasks</h5>
                                    <div className="d-flex align-items-center">
                                        <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
                                            <p className="card-text">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" fill="currentColor" className="bi bi-card-checklist" viewBox="0 0 16 16">
                                                    <path d="M14.5 3a.5.5 0 0 1 .5.5v9a.5.5 0 0 1-.5.5h-13a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5zm-13-1A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2z"/>
                                                    <path d="M7 5.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5m-1.496-.854a.5.5 0 0 1 0 .708l-1.5 1.5a.5.5 0 0 1-.708 0l-.5-.5a.5.5 0 1 1 .708-.708l.146.147 1.146-1.147a.5.5 0 0 1 .708 0M7 9.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5m-1.496-.854a.5.5 0 0 1 0 .708l-1.5 1.5a.5.5 0 0 1-.708 0l-.5-.5a.5.5 0 0 1 .708-.708l.146.147 1.146-1.147a.5.5 0 0 1 .708 0"/>
                                                </svg>
                                            </p>
                                        </div>
                                        <div className="ps-3">
                                            <h6>5966</h6>
                                            <span className="text-warning small pt-1 fw-bold">50%</span> <span className="text-muted small pt-2 ps-1">increase</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-footer">
                                    <small className="text-muted">Last updated 20 mins ago</small>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row mb-5">
                    <div className="card dataCard  revenueCard">
                        <div className="card-body">
                            <Line 
                                data= {{
                                    labels: revenueData.map((data) => data.label),
                                    datasets: [
                                        {
                                            label: "Revenue",
                                            data: revenueData.map((data) => data.revenue), 
                                            backgroundColor: "#00FF00",
                                            hoverBorderColor: "#00FF00",
                                        },
                                        {
                                            label: "Costs",
                                            data: revenueData.map((data) => data.cost), 
                                            backgroundColor: "#FF3030",
                                            hoverBorderColor: "#FF3030",
                                        },
                                        {
                                            label: "Customers",
                                            data: revenueData.map((data) => data.customers),
                                            backgroundColor: "#0000FF",
                                            hoverBorderColor: "#0000FF",
                                        },
                                    ],
                                }}
                                options = {{
                                    elements: {
                                        line: {
                                            tension: 0.5,                                            
                                        }
                                    },
                                    plugins: {
                                        title: {
                                            text: "Revenue Sources"
                                        }
                                    }
                                }}
                            />
                        </div>
                    </div>
                    </div>
                
                    <div className="row mb-5">
                        <div className="col">
                            <div className="card">
                                <div className="card-body">
                                    <Bar
                                        data= {{
                                            labels: sourceData.map((data) => data.label),
                                            datasets: [
                                                {
                                                    label: "Count",
                                                    data: sourceData.map((data) => data.value), 
                                                    backgroundColor: [
                                                        "rgba(43, 63, 229, 0.8)",
                                                        "rgba(250, 192, 19, 0.8)",
                                                        "rgba(253, 135, 135, 0.8)",
                                                    ],
                                                    borderRadius: 5,
                                                },
                                            ],
                                        }}
                                        options = {{
                                            plugins: {
                                                title: {
                                                    text: "Sales Sources"
                                                }
                                            }
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col">
                            <div className="card">
                                <div className="card-body">
                                    <Doughnut
                                        data= {{
                                            labels: sourceData.map((data) => data.label),
                                            datasets: [
                                                {
                                                    label: "Count",
                                                    data: sourceData.map((data) => data.value), 
                                                    backgroundColor: [
                                                        "rgba(43, 63, 229, 0.8)",
                                                        "rgba(250, 192, 19, 0.8)",
                                                        "rgba(253, 135, 135, 0.8)",
                                                    ],
                                                    borderColor: [
                                                        "rgba(43, 63, 229, 0.8)",
                                                        "rgba(250, 192, 19, 0.8)",
                                                        "rgba(253, 135, 135, 0.8)",
                                                    ]
                                                },
                                            ],
                                        }}
                                        options = {{
                                            plugins: {
                                                title: {
                                                    text: "Revenue Sources"
                                                }
                                            }
                                        }}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="col">
                            <div className="card">
                                <div className="card-body">
                                    <Radar 
                                        data={
                                                {
                                                    labels: radarData1st.map((data) => data.label),
                                                    datasets: [
                                                        {
                                                            label: "Year of inception (2022)",
                                                            data: radarData1st.map((data) => data.value),
                                                            fill: true,
                                                            backgroundColor: 'rgba(255, 99, 132, 0.2)',
                                                            borderColor: 'rgb(255, 99, 132)',
                                                            pointBackgroundColor: 'rgb(255, 99, 132)',
                                                            pointBorderColor: '#fff',
                                                            pointHoverBackgroundColor: '#fff',
                                                            pointHoverBorderColor: 'rgb(255, 99, 132)'
                                                        },
                                                        {
                                                            label: "2nd Year of inception (2024)",
                                                            data: radarData2nd.map((data) => data.value),
                                                            fill: true,
                                                            backgroundColor: 'rgba(54, 162, 235, 0.2)',
                                                            borderColor: 'rgb(54, 162, 235)',
                                                            pointBackgroundColor: 'rgb(54, 162, 235)',
                                                            pointBorderColor: '#fff',
                                                            pointHoverBackgroundColor: '#fff',
                                                            pointHoverBorderColor: 'rgb(54, 162, 235)'
                                                        }
                                                        
                                                    ]
                                                }
                                                
                                            }
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}